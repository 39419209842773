import { useCallback }  from 'react';
import Message          from './message';
import SDate            from 'app/common/utils/date-api';

const Messages = props => {
    const p                 = props;

    const renderMessages = useCallback(() => {
        return p.messages.map( (item, i) => 
            <Message 
                type            = { item.type } 
                message         = { item.message } 
                skipAnimation   = { p.skipAnimation } 
                selectProduct   = { id => p.selectProduct(id) }
                key             = { 'p-' + i }
                scrollToBottom  = { () => p.scrollToBottom() }
                sourceProjectId = { p.sourceProjectId }
                isProductSelected = { p.isProductSelected }
            /> 
        )
    }, [ p.messages, p.isProductSelected ]) // eslint-disable-line

    return (
        <div className="sc-chat-container-messages">
            <div className="scm-date">{ SDate.format(new Date(), 'dddd, d MMMM') }</div>
            <div className="sc-chat-container-messages-p">
                { renderMessages() }
            </div>
        </div>
    )
}

export default Messages;