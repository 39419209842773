import Keycloak     from 'keycloak-js';
import products     from 'app/common/constants/products';
import axios        from 'axios';

const AuthorizationContext = (() => {
    let _keycloak = null;

    const context = { 
        _isAuthenticated    : false,
        _isInitializing     : false,
        _isInitialized      : false,
        _token              : {},
        _instance           :   new Keycloak({
                                    url         : `${window.REACT_APP_SSO_URL || process.env.REACT_APP_SSO_URL}`,
                                    realm       : 'SIMPRA',
                                    clientId    : 'simpra-products-ui',
                                }),

        get keycloakInstance()              { return this._instance },
        get isAuthenticated()               { return this._isAuthenticated },
        set isAuthenticated(val)            { this._isAuthenticated = val },
        
        get isInitializing()                { return this._isInitializing },
        set isInitializing(val)             { this._isInitializing = val },
        
        get isInitialized()                 { return this._isInitialized },
        set isInitialized(val)              { this._isInitialized = val },
        
        get token()                         { return this._token },
        set token(val)                      { this._token = val },

        get refreshToken()                  { return this._refreshToken },
        set refreshToken(val)               { this._refreshToken = val },

        isAvailable() {
            return !this.isAuthenticated && !this.isInitializing && !this.isInitialized
        },

        init({ dispatch }) {
            if (!this.isInitializing && !this.isInitialized) {

                this.isInitializing = true;

                return new Promise((resolve, reject) => {
                    try {
                        return this.keycloakInstance.init({
                            responseMode: "query",
                            onLoad: "check-sso",
                            silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`
                        }).then( async response => {
                            if (response) {
                                await this.assignContextArguments(response, dispatch);
                            } else {
                                this.keycloakInstance.login();
                            }
                            resolve(response);
                        }).catch(err => {
                            this.isAuthenticated    = false;
                            this.isInitializing     = false;
    
                            reject(err);
                        });
                    } catch(err) {
                        console.log(err)
                        reject(err);
                    }

                });
            }
        },
        assignContextArguments(loginResult, dispatch) {
            return new Promise(async (resolve, reject) => {
                try {
                    const token = this.keycloakInstance?.tokenParsed || {};

                    dispatch({
                        type: 'provider/changeLanguage',
                        payload: token.locale
                    });
                    
                    this.isAuthenticated            = loginResult;
                    this.isInitializing             = false;
                    this.isInitialized              = true;
                    this.token                      = token;
                    this.refreshToken               = this.keycloakInstance.refreshToken;
                    this.user.profile               = await this.keycloakInstance.loadUserProfile();
                    this.user.memberOf              = token.memberOf || [];
                    this.user.allProducts           = token.memberOf?.map( item => item.replace('/', '').toLowerCase() ) || []
                    this.user.authorizedProducts    = products.filter( item => this.user.allProducts.includes(item.name) );
                    this.user.unAuthorizedProducts  = products.filter( item => !this.user.allProducts.includes(item.name) );
    
                    resolve();
                } catch(err) {
                    reject(err)
                }
            })
        },
        logout() {
            this.keycloakInstance.logout();
        },
        getAdminToken() {
            return 'realms/master/protocol/openid-connect/token'
        },
        user  : {
            _profile                            : {},
            _memberOf                           : [],
            _allProducts                        : [],
            _authorizedProducts                 : [],
            _unAuthorizedProducts               : [],

            get profile()                       { return this._profile },
            set profile(val)                    { this._profile = val },

            get allProducts()                   { return this._allProducts },
            set allProducts(val)                { this._allProducts = val },
            
            get memberOf()                      { return this._memberOf },
            set memberOf(val)                   { this._memberOf = val },
            
            get authorizedProducts()            { return this._authorizedProducts },
            set authorizedProducts(val)         { this._authorizedProducts = val },

            get unAuthorizedProducts()          { return this._unAuthorizedProducts },
            set unAuthorizedProducts(val)       { this._unAuthorizedProducts = val },

            updateProfile(payload) {
                return new Promise((resolve, reject) => {
                    axios({ 
                        method      : 'POST', 
                        url         : `${window.REACT_APP_SSO_URL || process.env.REACT_APP_SSO_URL}realms/SIMPRA/account/`,
                        data        : payload,
                        headers     : {
                            Authorization: 'Bearer ' + context.keycloakInstance.token,
                            'Content-Type': 'application/json',
                        },
                    }).then( async _response => {
                        context.user.profile = await context.keycloakInstance.loadUserProfile();
                        resolve(true);
                    })
                    .catch(err => {
                        reject(err);
                    })
                })
            },
            updateIYSPermissions(payload) {
                return new Promise((resolve, reject) => {
                    axios({ 
                        method      : 'PUT', 
                        url         : `${window.REACT_APP_SSO_API || process.env.REACT_APP_SSO_API}api/Iys/UpdateIysPermissions`,
                        data        : payload,
                        headers     : {
                            SourceProject   : window.REACT_APP_SOURCE_PROJECT_ID || process.env.REACT_APP_SOURCE_PROJECT_ID,
                            'Content-Type': 'application/json',
                        },
                    }).then( async _response => {
                        context.user.profile = await context.keycloakInstance.loadUserProfile();
                        resolve(true);
                    })
                    .catch(err => {
                        reject(err);
                    })
                })                
                //https://sso-test-api.simprasuite.com//api/Iys/UpdateIysPermissions
                //header: SourceProject env.REACT_APP_SOURCE_PROJECT_ID
                /*
                    {
                    "email": "string",
                    "allowSms": true,
                    "allowPhone": true,
                    "allowEmail": true
                    }
                */
            },
            updatePassword(payload) {
                return new Promise((resolve, reject) => {
                    axios({ 
                        method      : 'PUT', 
                        url         : `${window.REACT_APP_SSO_URL || process.env.REACT_APP_SSO_URL}admin/realms/master/users/${context.keycloakInstance.tokenParsed.sub}/reset-password`,
                        data        : payload,
                        headers     : {
                            Authorization: 'Bearer ' + context.keycloakInstance.token,
                            'Content-Type': 'application/json',
                        },
                    }).then( _response => {
                        resolve(true);
                    })
                    .catch(err => {
                        reject(err);
                    })
                })
            }
        }
    }

    if (!_keycloak) {
        _keycloak = { ...context }
    }

    return _keycloak
})();

export default AuthorizationContext