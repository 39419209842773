import { useQuery, useMutation }    from '@tanstack/react-query';
import RestClient                   from "app/client/rest";

export const useSession = (data, shouldFetch) => {
    const initialData = null;

    return useQuery({
        queryKey: ['chatbotSession'],

        placeholderData: (previousData) => {
            if (!previousData) return initialData
            return previousData
        },
        enabled: shouldFetch,
        staleTime: 0,
        gcTime: 0,
        queryFn: async () => {
            const request = {
                url     : `/api/chatbot/session`,
                method  : 'POST',
                data    : data
            }

            const res = await RestClient.fetch(request)

            return res;
        },
        onError: err => {
            console.log(err)
        },
    });
};

export const useSelectProduct = () => {
    return useMutation({
        mutationKey: ['selectProduct'],
        mutationFn: async data => {
            const res = await RestClient.fetch({
                url: `/api/chatbot/session`,
                method: 'POST',
                data: data
            })
            return res
        },
        onError: err => {
            console.log(err)
        },
    });
};

export const usePost = () => {
    return useMutation({
        mutationKey: ['sendMessage'],
        mutationFn: async data => {
            const res = await RestClient.fetch({
                url: `/api/chatbot/chat`,
                method: 'POST',
                data: {
                    question: data
                }
            })

            return res
        },
        onError: err => {
            console.log(err)
        },
    });
};


export const useEndChat = () => {
    return useMutation({
        mutationKey: ['endChat'],
        mutationFn: async data => {
            const res = await RestClient.fetch({
                url: `/api/chatbot/session/close`,
                method: 'PUT',
                data
            })

            return res
        },
        onError: err => {
            console.log(err)
        },
    });
};