import {createSlice} from '@reduxjs/toolkit';

const provider = createSlice({
    name        : 'provider',
    initialState: {
        displayLanguage     : 'tr'
    },
    reducers: create => ({
        changeLanguage: create.reducer( (state, action) => {
            state.displayLanguage   = action.payload
        }),
        
    })
})

export default provider