/* eslint-disable */
import DateCore            from 'app/common/utils/date-core';

class SDate {
    isValidFormat(date, format) {
        const splitters     = [ '.', '/', '-', ' ' ];
        const dayRegExp     = /^(0[1-9]|[12][0-9]|3[01]|[1-9])$/
        const monthRegExp   = /^(0[1-9]|1[012]|[1-9])$/;
        const yearRegExp    = /^(19|2[0-9])\d\d$/;
        const splitter      = splitters.find(s => format.includes(s))

        if(!date || !splitter) return false;
       
        let dArr = date.split(splitter);
        let fArr = format.split(splitter);

        return fArr.every((f, i) => {
            let returnValue = null;

            if(f === 'dd') {
                returnValue = dayRegExp.test(dArr[i]);
            }

            if(f === 'MM') {
                returnValue = monthRegExp.test(dArr[i]);
            }

            if(f === 'yyyy') {
                returnValue = yearRegExp.test(dArr[i]);
            }
            return returnValue;
        })
    }

    roundMinute(date, minute) {
        const d = new Date(date);
        const m = Math.ceil(d.getMinutes() / minute);
        const h = d.getMinutes() !== 0 && (m * minute) % 60 === 0 ? d.getHours() + 1 : d.getHours(); 
        d.setMinutes((m * minute) % 60);
        d.setHours(h);
        return d;
    }

    isDate(date = new Date()) {
        return new Date(date) !== 'Invalid Date';
    }

    clearType(date = new Date()) {
        let charControl = new RegExp('[^\s\:\d]');
        return date.replace(charControl, '-')
    }

    addMinute(date = new Date(), minute = 0, format) {
        let d = this.isDate(date)
                    ? new Date(date)
                    : new Date();
        d.setTime(d.getTime() + (minute * 1000 * 60));
        return format
                    ? this.format(d, format)
                    : d;
    }

    addHour(date = new Date(), hour = 0) {
        let d = this.isDate(date)
                    ? new Date(date)
                    : new Date() ;
        d.setTime(d.getTime() + (hour * 1000 * 60 * 60));
        return d;
    }

    addDay(date = new Date(), days = 0) {
        let d = this.isDate(date)
                    ? new Date(date)
                    : new Date();
        d.setDate(d.getDate() + days);
        return d;
    }

    addMonth(date = new Date(), months = 0) {
        let d = this.isDate(date)
                    ? new Date(date)
                    : new Date() ;
        d.setDate(1);
        d.setMonth(d.getMonth() + months);
        return d;
    }

    addYear(date = new Date(), year = 0) {
        let d = this.isDate(date)
                    ? new Date(date)
                    : new Date()
        d.setFullYear(d.getFullYear() + year);
        return d;
    }

    minusMinute(date = new Date(), minute = 0) {
        let d = this.isDate(date)
                    ? new Date(date)
                    : new Date() ;
        d.setTime(d.getTime() - (minute * 1000 * 60));
        return d;
    }

    minusSecond(date = new Date(), second = 0) {
        let d = this.isDate(date)
                    ? new Date(date)
                    : new Date() ;
        d.setTime(d.getTime() - (second * 1000));
        return d;
    }

    diffDay(date = new Date(), days = 0) {
        let d = this.isDate(date)
                    ? new Date(date)
                    : new Date() ;
        d.setDate(d.getDate() - days);
        return d;
    }

    minusHour(date = new Date(), hour = 0) {
        let d = this.isDate(date)
                    ? new Date(date)
                    : new Date() ;
        d.setTime(d.getTime() - (hour * 1000 * 60 * 60));
        return d;
    }

    minusDay(date = new Date(), days = 0) {
        let d = this.isDate(date)
                    ? new Date(date)
                    : new Date() ;
        d.setTime(d.getTime() - (days * 1000 * 60 * 60 * 24));
        return d;
    }

    minusYear(date = new Date(), year = 0) {
        let d = this.isDate(date)
                    ? new Date(date)
                    : new Date()
        d.setFullYear(d.getFullYear() - year);
        return d;
    }

    diffDate(date1 = new Date(), date2 = new Date()) {
        let d1 = this.isDate(date1)
                    ? new Date(date1)
                    : new Date();
        let d2 = this.isDate(date2)
                    ? new Date(date2)
                    : new Date();
        const diffTime = Math.abs(d1 - d2);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays; 
    }

    diffHour(date1 = new Date(), date2 = new Date()) {
        let d1 = this.isDate(date1)
                    ? new Date(date1)
                    : new Date();
        let d2 = this.isDate(date2)
                    ? new Date(date2)
                    : new Date();
        const diffTime = d2 - d1;
        const diffHours = Math.floor(diffTime / (60 * 60 * 1000));
        return diffHours; 
    }

    diffMinute(date1 = new Date(), date2 = new Date()) {
        let d1 = this.isDate(date1)
                    ? new Date(date1)
                    : new Date();
        let d2 = this.isDate(date2)
                    ? new Date(date2)
                    : new Date();
        const diffTime = Math.abs(d1 - d2);
        const diffMinutes = Math.ceil(diffTime / (1000 * 60));
        return diffMinutes; 
    }

    getDateTime(date = new Date()) {
        const d = this.isDate(date)
                    ? date
                    : new Date();
        return this.cheapTimeFormat(d);
    }

    isToday(date = new Date()) {
        let d = this.isDate(date)
                    ? new Date(date)
                    : new Date();
        let today = new Date();
        return d.getDate() === today.getDate()
                && d.getMonth() === today.getMonth()
                && d.getFullYear() === today.getFullYear();
    }

    isWeekendDay(date = new Date()) {
        return new Date(date).getDay() === 6 || new Date(date).getDay() === 0;
    }

    isBigger(date = new Date(), from = new Date()) {
        if(this.isDate(date) && this.isDate(from)) {
            return new Date(date).getTime() > new Date(from).getTime();
        }
        return false;
    }

    isEqual(date = new Date(), from = new Date()) {
        if(this.isDate(date) && this.isDate(from)) {
            return new Date(date).valueOf() === new Date(from).valueOf();
        }
        return false;
    }

    isBiggerOrEqual(date = new Date(), from = new Date()) {
        if(this.isDate(date) && this.isDate(from)) {
            return new Date(date) >= new Date(from);
        }
        return false;
    }

    isSmaller(date = new Date(), from = new Date()) {
        if(this.isDate(date) && this.isDate(from)) {
            return new Date(date).getTime() < new Date(from).getTime();
        }
        return false;
    }

    isSmallerOrEqual(date = new Date(), from = new Date()) {
        if(this.isDate(date) && this.isDate(from)) {
            return new Date(date) <= new Date(from);
        }
        return false;
    }

    convertToENDate(date, format) {
        let enFormat            = 'MM/dd/yyyy';
        const splitters         = [ '.', '/', '-', ' ' ];
        const splitter          = splitters.find(s => format.includes(s))
        const splittedFormat    = format.split(splitter); // 'dd/mm/yyyy' => [ 'dd', 'mm', 'yyyy' ]
        const splittedDate      = date.split(splitter); // '10/09/1993' => [ '10', '09', '1993' ]

        return splittedFormat.reduce((dt, f, index) => dt.replace(f, splittedDate[index]), enFormat)
    }

    cheapDateFormat(date = new Date(), timeZone = DateCore.timeZone()) {
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let d = this.isDate(date)
                    ? new Date(date)
                    : new Date();

        if (timeZone && tz !== timeZone) {
            d = this.changeTimezone(d, timeZone);
        }
        return `${d.getFullYear()}/${('0' + (d.getMonth() + 1).toString()).slice(-2)}/${('0' + d.getDate()).toString().slice(-2)}`
    }

    cheapDateTimeFormat(date = new Date(), timeZone = DateCore.timeZone()) {
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let d = this.isDate(date)
                    ? new Date(date)
                    : new Date();

        if (timeZone && tz !== timeZone) {
            d = this.changeTimezone(d, timeZone);
        }
        return `${d.getFullYear()}/${('0' + (d.getMonth() + 1).toString()).slice(-2)}/${('0' + d.getDate()).toString().slice(-2)} ${('0' + d.getHours()).toString().slice(-2)}:${('0' + d.getMinutes()).toString().slice(-2)}`
    }

    cheapTimeFormat(date = new Date(), timeZone = DateCore.timeZone()) {
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let d = this.isDate(date)
                    ? new Date(date)
                    : new Date();

        if (timeZone && tz !== timeZone) {
            d = this.changeTimezone(d, timeZone);
        }

        return `${('0' + d.getHours()).toString().slice(-2)}:${('0' + d.getMinutes()).toString().slice(-2)}`
    }

    changeTimezone(date, ianatz = DateCore.timeZone()) {
        const invdate = new Date(date.toLocaleString('en-EN', {
            timeZone: ianatz
        }));

        const diff = date.getTime() - invdate.getTime();

        return new Date(date.getTime() - diff); 
    }

    format(
        date                = new Date(), 
        formatString        = 'yyyy/MM/dd',
        defaultLanguage     = 'tr-TR',
        timeZone            = DateCore.timeZone()
    ) {
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let f = formatString;
        let l = defaultLanguage;
        let originalDate = date
                            ? date.toString().replace('Z', '')
                            : new Date().toString().replace('Z', '');

        let d = this.isDate(originalDate) ? new Date(originalDate) : new Date();

        if (timeZone && tz !== timeZone) {
            d = this.changeTimezone(d, timeZone);
        }

        let transformedDateString = '';

        //d = String(d).indexOf('Z') > -1 ? d.replce('Z', '') : d;

        transformedDateString = f;

        if (formatString === 'yyyy/MM/dd') {
            let month = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
            let day   = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();

            return d.getFullYear() + '/' + month + '/' + day
        }

        transformedDateString = transformedDateString.replace('yyyy', d.toLocaleString(l, { year: 'numeric' }))
                                                    .replace('y', d.toLocaleString(l, { year: '2-digit' }))
                                                    .replace(new RegExp(
                                                        /\bMMMM\b/), 
                                                        d.toLocaleString(l, { month: 'long' })
                                                    )
                                                    .replace(new RegExp(
                                                        /\bMMM\b/), 
                                                        d.toLocaleString(l, { month: 'short' })
                                                    )
                                                    .replace(new RegExp(
                                                        /\bMM\b/), 
                                                        d.toLocaleString(l, { month: '2-digit' })
                                                    )
                                                    .replace(new RegExp(
                                                        /\bM\b/), 
                                                        d.toLocaleString(l, { month: 'numeric' })
                                                    )                                                    
                                                    .replace(new RegExp(
                                                        /\bdddd\b/), 
                                                        d.toLocaleString(l, { weekday: 'long' })
                                                    )                                                    
                                                    .replace(new RegExp(
                                                        /\bddd\b/), 
                                                        d.toLocaleString(l, { weekday: 'short' })
                                                    )
                                                    .replace(new RegExp(
                                                        /\bdd\b/), 
                                                        d.toLocaleString(l, { day: '2-digit' })
                                                    )
                                                    .replace(new RegExp(
                                                        /\bd\b/), 
                                                        d.toLocaleString(l, { day: 'numeric' })
                                                    )
                                                    .replace(new RegExp(
                                                        /\bhh:Mi\b/),
                                                        d.toLocaleTimeString(l, { hourCycle: 'h23', hour: '2-digit', minute: '2-digit' })
                                                    )
                                                    .replace(new RegExp(
                                                        /\bhh:Mi:ss\b/),
                                                        d.toLocaleTimeString(l, { hourCycle: 'h23', hour: '2-digit', minute: '2-digit', second: '2-digit' })
                                                    )
                                                    .replace(new RegExp(
                                                        /\bmm:ss\b/),
                                                        d.toLocaleTimeString(l, { minute: '2-digit', second: '2-digit' })
                                                    )
                                                    .replace(new RegExp(
                                                        /\bHH:mm\b/),
                                                        d.toLocaleTimeString(l, { hour: '2-digit', minute: '2-digit' })
                                                    )                                                    
                                                    .replace(new RegExp(
                                                        /\bhh:mm\b/),
                                                        d.toLocaleTimeString(l, { hour12: true, hour: '2-digit', minute: '2-digit' })
                                                    )                                                    
                                                    .replace(new RegExp(
                                                        /\bHH\b/),
                                                        d.toLocaleTimeString(l, { hourCycle: 'h23', hour: '2-digit' })
                                                    )                                                    
                                                    .replace(new RegExp(
                                                        /\bhh\b/),
                                                        d.toLocaleTimeString(l, { hour12: true, hour: '2-digit' })
                                                    )
                                                    .replace(new RegExp(
                                                        /\bH\b/),
                                                        d.toLocaleTimeString(l, { hourCycle: 'h23', hour: 'numeric' })
                                                    )
                                                    .replace(new RegExp(
                                                        /\bh\b/),
                                                        d.toLocaleTimeString(l, { hour12: true, hour: 'numeric' })
                                                    )
                                                    .replace(new RegExp(
                                                        /\bmm\b/),
                                                        d.toLocaleTimeString(l, { minute: '2-digit' })
                                                    )
                                                    // .replace(new RegExp(
                                                    //     /\m\b/),
                                                    //     d.toLocaleTimeString(l, { minute: 'numeric' })
                                                    // )
                                                    .replace(new RegExp(
                                                        /\bss\b/), 
                                                        d.toLocaleString(l, { second: '2-digit' })
                                                    )
                                                    // .replace(new RegExp(
                                                    //     /\bs\b/), 
                                                    //     d.toLocaleString(l, { second: 'numeric'  })
                                                    // )
        return transformedDateString.trim();
    }
}

export default new SDate()
/* eslint-enable */