export const Button = props => {
    const p                 = props;

    const createComponentClasses = () => {
        const {
            colorType,
            size,
            embose,
            pill,
            plain,
            circle,
            dashed,
            outlined,
            full,
            justIcon,
            justContent,
            autoSize,
            className
        } = p;

        let classNames = ['simpra-button pink-shadow'];

        if (colorType)      classNames.push(colorType);
        if (size)           classNames.push(size);
        if (embose)         classNames.push('embose');
        if (pill)           classNames.push('pill');
        if (dashed)         classNames.push('dashed');
        if (plain)          classNames.push('plain');
        if (circle)         classNames.push('circle');
        if (outlined)       classNames.push('outlined');
        if (full)           classNames.push('full');
        if (justIcon)       classNames.push('just-icon');
        if (autoSize)       classNames.push('auto-size');
        if (justContent)    classNames.push('just-content');
        if (className)    Array.prototype.push.apply(classNames, className.split(' '));

        return [...new Set(classNames)].join(' ');
    }

    return (
        <input 
            className           = { createComponentClasses() }
            tabIndex            = "10" 
            type                = "button"
            value               = { p.label } 
            onClick             = { e => p.onClick(e) } 
            disabled            = { p.isDisabled ? 'disabled' : '' } 
            { ...p.attrs}
        />        
    )
}

export default Button;