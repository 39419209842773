const ChatContainer = props => {
    const p                 = props;

    return (
        <div className={ `sc-chat-container${p.isToggled ? ' toggled' : ''}` }>
            { p.children }
        </div>
    )
}

export default ChatContainer;