import React                          	from 'react';
import ReactDOM                       	from 'react-dom/client';
import App     							from './app';
import store     						from './store';
import { Provider } 					from 'react-redux'
import { QueryClientProvider }      	from '@tanstack/react-query';
import queryClient                  	from './app/client/query';

import './scss/app.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<Provider store={ store }>
		<React.StrictMode>
			<QueryClientProvider client={ queryClient }>
				<App />
			</QueryClientProvider>
		</React.StrictMode>
	</Provider>
);