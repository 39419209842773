import React        from 'react';

export const Textfield = props => {
    const p         = props;

    const renderErrorHelper = field => {
        return p.errors[field]?.length
                ? <span className={ `input-helper danger` }>{ p.errors[field][0].message }</span>
                : null
    }

    const onChange = (e) => {
        if (typeof p.onChange === 'function')
            p.onChange(e.target.value);
    }

    return (
        <div className="simpra-input">
            <label>{ p.label }</label>
            <input 
                className       = "simpra-input" 
                defaultValue    = { p.value } 
                type            = "text" 
                onChange        = { e => onChange(e) }
                placeholder     = { p.placeholder || '' }
                { ...p.attrs}
            />
            { renderErrorHelper(p.fieldName) }
        </div>        
    )
}

export default Textfield;