import { useState, useEffect, useMemo }  from 'react';
import countries                from './countries';
import useTranslate                 from 'app/common/hooks/translate';

export const Phonepicker = props => {
    const p                 = props;
    const t                 = useTranslate();

    const [ s, setState ]   = useState({
        code: p.code || "",
        phone: "",
    })

    const getMaskedValue = (v) => {
        const currentCountry = s.code && countries.find( c => c.phoneCode === parseInt(s.code) );

        if (currentCountry?.maskFormat && v) {
            const maskArray = currentCountry.maskFormat.split('');
            const stripedValue = Array.from(v).filter(char => /\d/.test(char));
            return maskArray.map((char, index) => {
                if (stripedValue.length === 0) return null;
                if (char !== '#') return char;
                return stripedValue.shift();  
            }).join('')
        }

        return v;
    }

    useEffect(() => {
        setState({
            code: p.code || "",
            phone: p?.number ? getMaskedValue(p.number) : "",
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getUnmaskedValue = (v) => {
        const currentCountry = !!(s.code) && countries.find( c => c.phoneCode === parseInt(s.code) );

        if ( currentCountry?.maskFormat && !!(v) ) {
            const maskedValue = getMaskedValue(v);     
            const valueArray  = maskedValue.split('');
            const maskArray   = currentCountry.maskFormat.split('');
            return valueArray.map((char, index) => {
                if (maskArray[index] !== '#') return null;
                return char;
            }).join('')
        }

        return v;
    }

    const onChangeHandler = e => {
        e.persist();
        const { onChange }  = p;
        const value         = e.target.value;

        setState(prevState => ({
            ...prevState,
            phone       : getMaskedValue(value)           
        }));

        if (onChange) {
            const unmaskedValue = getUnmaskedValue(value);
            onChange({ phone: unmaskedValue, code: s.code});
        }
    }

    const phoneCodeChangeHandler = e => {
        const { onChange }  = p;
        const value         = e.target.value;

        setState(prevState => ({
            ...prevState,
            code        : value,
            phone       : getMaskedValue(s.phone)           

        }));

        if (onChange) {
            const unmaskedValue = getUnmaskedValue(s.phone);
            onChange({ phone: unmaskedValue, code: value});
        }
    }

    const renderErrorHelper = field => {
        return p?.errors?.[field]?.length
                ? <span className={ `input-helper danger` }>{ p.errors[field][0].message }</span>
                : null
    }

    const getCountryOptions = useMemo(() => {
        const returnValue = countries.map( (o, i) => (<option key={ i } value={ o.phoneCode }>(+{o.phoneCode}) {o.name}</option>));

        returnValue.unshift(<option key="start" value="">Ülke...</option>);
        return returnValue;
    }, [])

    return (
        <div className={ `simpra-phonepicker${p.errors?.number || p.errors?.code ? ' error' : ''}` }>
            <select 
                defaultValue            = { s.code } 
                id                      = 'phonepicker-code'
                className               = 'simpra-select'
                name                    = 'phonepicker-code'
                onInput                = { e => phoneCodeChangeHandler(e) }
                disabled                = { p.isDisabled ? 'disabled' : '' }
                multiple                = { false }
            >
                { getCountryOptions }                                    
            </select>
            <input 
                type                    = 'text'
                name                    = 'phonepicker-phone'
                autoComplete            = {'false'}
                onInput                = { e => onChangeHandler(e) }
                value                   = { s.phone }
                disabled                = { (p.isDisabled || !s.code.length) ? 'disabled' : '' }
                placeholder             = { t('Enter_Your_Phone') }
            ></input>
            { renderErrorHelper('number') }
        </div>        
    )
}

export default Phonepicker;