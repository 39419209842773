import axios                            from 'axios';
import AuthorizationContext     		from 'auth/keycloak-instance';

axios.defaults.validateStatus = (status) => {
    return status >= 200 && status < 400;
};

const RestClient = (() => {
    let _rest = null;

    const rest = {
        name            : 'Rest-Client',
        _source         : [],
        _isFetching     : false,

        get signal()                    { return this._source },
        get status()                    { return this._isFetching },
        get isFetching()                { return this._isFetching },
        get dispatch()                  { return this._dispatch },
        get source()                    { return this._source },

        set signal(val)                 { this._signal = val },
        set source(val)                 { this._source = val },
        set isFetching(val)             { this._isFetching = val },
        set dispatch(val)               { this._dispatch = val },

        init(dispatch) {
            rest.dispatch = dispatch
        },

        createSpecificRequestParams() {
            rest.isFetching = true;
            const source = axios.CancelToken.source();
            rest.source.push(source);

            return source;
        },

        fetch(param) {
            const { url, method, data, contentType, isNotCancellable, responseType } = param;
            return new Promise( (resolve, reject) => {
                
                axios({ 
                    method      : method, 
                    url         : `${window.REACT_APP_API_URL || process.env.REACT_APP_API_URL}${url}`,
                    data        : data,
                    responseType: responseType,
                    headers     : {
                        'Content-Type': contentType || 'application/json',
                        'Authorization': 'Bearer ' + AuthorizationContext.keycloakInstance.token
                    },
                }).then( response => {
                    rest.isFetching = false;
                    resolve(response.data);
                }).catch( err => {
                    if (!isNotCancellable && axios.isCancel(err)) {
                        rest.source = rest.source.filter( s => {
                            return s.token !== err.message
                        });
                        rest.isFetching = false;

                        reject({
                            message: 'Token canceled'
                        })
                    }

                    if (
                        (err.status && err.status === 401)
                        || (err.response && err.response.status === 401)
                    )
                    window.location = '/auth/auto-logout'

                    rest.isFetching = false;
                    reject(err);
                });
            });
        } 
    };

    return () => {
        if (!_rest) {
            _rest = rest;
        }
        return _rest;
    };
})();

export default RestClient();
