const Input = props => {
    const p                 = props;

    const checkForPosting = e => {
        if (e.keyCode === 13 && e.target?.value?.length) {
            p.sendMessage(e.target.value);
            e.target.value = '';
        }
    }
    
    const sendPost = e => {
        const inputItem = document.getElementsByClassName('message-input')[0];
        if (inputItem?.value?.length) {
            p.sendMessage(inputItem.value);
            inputItem.value = '';
        }
    }

    return (
        <div className="sc-chat-container-input">
            <input disabled={ p.isDisable } type="text" className="message-input" onKeyUp={ e => checkForPosting(e) } />
            <button onClick={ () => sendPost() } disabled={ p.isDisable } className={ `submit` } />
        </div>
    )
}

export default Input;