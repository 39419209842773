import { ReactComponent as UrlIcon }    from 'assets/images/url-icon.svg'
import productUrls                      from 'app/common/constants/product-urls';
import useTranslate                     from 'app/common/hooks/translate';

const Product = props => {
    const { 
        item, 
        index, 
        authorizedProducts 
    }               = props;
    const t         = useTranslate();

    const getProductUrl = () => {
        const appEnv = window.REACT_APP_ENV || process.env.REACT_APP_ENV || 'test';

        return productUrls?.[appEnv]?.[item.name] || item.url ;
    }

    const getProductLandingUrl = () => {
        return item.landing;
    }

    return (
        <div 
            className   = { `product ${item.name.replace('c&p', 'checkandplace')} item-${index + 1}`} key={ 'pro-' + index }
            style       = {{ minHeight: 141 * Math.ceil(authorizedProducts.length / 4) }}
        >
            { /* eslint-disable-next-line */ }
            <a className="app-link" rel="noopener noreferrer"
                href={ '#' } 
                onTouchStart={ () => props.toggleApplicationLoader(getProductUrl()) }
                onClick={ () => props.toggleApplicationLoader(getProductUrl()) }
            />
            <div className={ `icon ${item.name.replace('c&p', 'checkandplace')}` } />
            <div className="name">
                <div>
                    { item.text }
                    <span>{ item.desc }</span>
                </div>
            </div>
            <div className="url">
            { /* eslint-disable-next-line */ }
                <a href={ getProductLandingUrl() }
                    target="_blank"
                    rel="noopener noreferrer">
                    <UrlIcon />
                    <div className="tooltip">{ t('Learn_More') }</div>
                </a>
            </div>
            
        </div>
    )
}

export default Product