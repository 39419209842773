import React                          	from 'react';
import { RouterProvider, router }     	from 'app/router';
import AuthorizationContext     		from 'auth/keycloak-instance';
import { 
	useDispatch
}                                  		from 'react-redux';

const App = _props => {
	const dispatch 			= useDispatch();
	const [ s, setState ] 	= React.useState({
		isLoading 				: false,
		isAuthenticated			: false,
		isError: false
	})

	React.useEffect(() => {
		if (AuthorizationContext.isAvailable()) {
			setState(prevState => ({ ...prevState, isLoading: true }));
			AuthorizationContext.init({ dispatch }).then( response => {
				if (response) {
					setState(prevState => ({ ...prevState, isAuthenticated: true, isLoading: false }));					
				}
			}).catch( err => {
				AuthorizationContext.keycloakInstance.login();
				setState(({ isError: true, isAuthenticated: false, isLoading: false }));		
			})
		}
	}, [ dispatch ])


	const renderContent = () => {
		return !AuthorizationContext.isInitialized || s.isLoading
				?	<div id="application-loader" />
				:	<RouterProvider router={ router } />
	}

	return renderContent()
}

export default App