import { Link }                 from "react-router-dom";
import AuthorizationContext     from 'auth/keycloak-instance';
import useTranslate             from 'app/common/hooks/translate';

const WelcomeMessage = props => {
    const t         = useTranslate();
    const p         = props;
    return (
        <div className="welcome-message">
            <h1>{ t('Welcome') } { AuthorizationContext.token.given_name }!</h1>
            <Link to="profile-settings">{ t('Profile_Settings') }</Link>
            <Link to="#" onClick={ () => p.openPermissionModal() }>{ t('My_Permissions') }</Link>
            {/* <div className="logout-button" onClick={ () => AuthorizationContext.logout() }>
                X
            </div> */}
        </div>
    )
}

export default WelcomeMessage