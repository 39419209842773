import {
    createBrowserRouter,
    RouterProvider,
}                           from "react-router-dom";

import * as Views           from '../views';
//import AuthorizedRoute      from './authorized-route';
import Layout               from '../layout';

const router = createBrowserRouter([
    {
        path    :   '/',
        element :   <Layout>
                        <Views.ProductSelection />
                    </Layout>
                    
    },
    {
        path    :   '/profile-settings',
        element :   <Layout screenMode="full">
                        <Views.ProfileSettings />
                    </Layout>
    },
]);

export {
    RouterProvider,
    router
}