import React            from 'react';

class Label extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };
    }

    render(){
        let { children } = this.props;
        return(
            <div className="simprakit-switch-label">
                { children }
            </div>
        )
    }
}

export default Label;