import {
    Main,
    Simpra,
    LandingContent,
    ApplicationContent
}                           from './components'
const Layout = props => {

    return (
        <Main>
            <ApplicationContent screenMode={ props.screenMode }>
                <Simpra />
                { props.children }
            </ApplicationContent>
            {
                !props.screenMode
                    ?   <LandingContent />
                    :   null
            }
        </Main>
    )
}

export default Layout