import { useState, useEffect }  from 'react';

const WelcomeText = props => {

    const [ s, setState ] = useState(0);

    useEffect(() => {
        let ci
        ci = setInterval(() => {
            setState(s === 0 ? 1 : 0);
        }, 4000);

        return (() => {
            clearInterval(ci);
        })
    })

    return (
        <div className="sc-welcome-text">
            <span className={`line${s === 0 ? ' toggled' : ''}`}>{ `👋 Hi ${props.firstName}. I am Simpra AI` }</span>
            <span className={`line${s === 1 ? ' toggled' : ''}`}>Ask me about our products.</span>
        </div>
    )
}

export default WelcomeText;