import { useState }                         from 'react';

const useErrors = _props => {
    const [ errors, setErrors ] = useState({});

    const clearFormErrorData = (field) => {
        const newErrors = Object.keys(errors)
            .filter(e => e !== field)
            .reduce( (newErrors, field, _i) => {
                newErrors[field] = errors[field];
                return newErrors;
            }, {});

        setErrors(newErrors);
    };

    return {
        errors                : errors,
        setErrors             : errors => setErrors(errors),
        clearFormErrorData    : (field) => clearFormErrorData(field),
    };
};

export default useErrors;
