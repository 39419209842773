import React       from 'react';

class SwitchButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };
    }

    createComponentClasses() {
        let {
            isFocused,
            value,
            className,
            isDisabled,
            switchOptions
        } = this.props;

        let classNames = ['simprakit-switch-base'];
        if(switchOptions) {
            let activeColorType = value ? switchOptions[0].colorType : switchOptions[1].colorType;
            if (activeColorType)    classNames.push(activeColorType);
        }
        if (value)              classNames.push('active');
        if (isFocused)          classNames.push('focused');
        if (isDisabled)         classNames.push('disabled');
        if (className)          Array.prototype.push.apply(classNames, className.split(' '));

        return [...new Set(classNames)].join(' ');
    }

    renderSwitchChildren() {
        let { value, switchOptions } = this.props;
        if(switchOptions === null) return;
        return value ? switchOptions[0].text : switchOptions[1].text; 
    }

    render(){
        let {
            onClick,
        } = this.props;

        return(
            <div 
                className   = { this.createComponentClasses() } 
                onClick     = { (e) => onClick(e) }
            >
                <span>{ this.renderSwitchChildren() }</span>
            </div>
        )
    }
}

export default SwitchButton;