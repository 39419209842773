import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import {
    Base,
    Label,
    Container,
    SwitchButton,
}                           from './components';

class Switch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            isFocused: false,
        };
    }

    componentDidMount(){
        let { value, autoFocus } = this.props;
        let defaultValue = value !== undefined
                                ? value
                                : false;
        this.setState({ 
            value       : defaultValue
        }, () => {
            if(autoFocus)
                this.refs.HANDLER_INPUT.refs.INPUT.focus();
        });
    }

    componentDidUpdate(prevProps) {
        if(prevProps.value !== this.props.value) {
            this.setState({ 
                value       : this.props.value
            });
        }
    }

    isInteractive() { return !this.props.isLoading && !this.props.isDisabled }

    onChangeHandler(e) {
        let { value } = this.state;
        let { onChange, isLocked } = this.props;
        if(!this.isInteractive()) {
            e.stopPropagation();
            return;
        }

        if (!isLocked) {
            this.setState({
                value: !value,
            }, () => {
                onChange(!value);
            });
        } else {
            onChange(!value);
        }
    }

    onClickHandler(e) {
        let { onClick, isLocked } = this.props;
        if(!this.isInteractive()) {
            e.stopPropagation();
            return;
        }
        this.refs.HANDLER_INPUT.refs.INPUT.focus();

        if (!isLocked) {
            this.setState({ isFocused: true }, () => {
                if(onClick)
                    onClick();
            });
        } else {
            if(onClick)
                onClick();
        }
    }

    onBlurHandler(e) {
        let { onBlur } = this.props;
        this.setState({ 
            isFocused   : false
        }, () => {
            if(onBlur)
                onBlur();            
        });
    }

    onKeyUpHandler(e){
        let { onKeyUp } = this.props;
        if(onKeyUp)
            onKeyUp(e);
    }

    onKeyDownHandler(e){
        let { onKeyDown } = this.props;
        if(onKeyDown)
            onKeyDown(e);
    }

    onKeyPressHandler(e) {
        let { onKeyPress } = this.props;
        if(e.charCode === 13) {
            this.onChangeHandler(e) 
        }
        if(onKeyPress)
            onKeyPress(e);
    }

    createComponentClasses() {
        let {
            colorType,
            inlineLabel,
            className,
        } = this.props;

        let classNames = ["simprakit-switch"];
        if (inlineLabel)    classNames.push('inline-label');
        if (colorType)      classNames.push(colorType);
        if (className)      Array.prototype.push.apply(classNames, className.split(' '));

        return [...new Set(classNames)].join(' ');
    }

    render() {
        let { value, isFocused } = this.state;
        let { label, isDisabled, attrs, switchOptions, isLoading } = this.props;
        return (
            <Container 
                className   = { this.createComponentClasses() } 
                attrs       = { attrs || {} }
            >
                <Label>{ label }</Label>
                <Base
                    ref             = 'HANDLER_INPUT'
                    value           = { value }
                    isDisabled      = { isDisabled }
                    onClick         = { e => this.onClickHandler(e) }
                    onBlur          = { e => this.onBlurHandler(e) }
                    onChange        = { e => this.onChangeHandler(e) }
                    onFocus         = { e => this.onClickHandler(e) }
                    onKeyUp         = { e => this.onKeyUpHandler(e) }
                    onKeyDown       = { e => this.onKeyDownHandler(e) }
                    onKeyPress      = { e => this.onKeyPressHandler(e) }
                />
                <SwitchButton
                    onClick         = { (e) => this.onChangeHandler(e) }
                    isFocused       = { isFocused }
                    isDisabled      = { isDisabled }
                    value           = { value }
                    switchOptions   = { switchOptions }
                    isLoading       = { isLoading }
                />
            </Container>
        )
    }
}

Switch.propTypes = {
    label           : PropTypes.string,
    attrs           : PropTypes.object,
    onClick         : PropTypes.func,
    onChange        : PropTypes.func,
    onBlur          : PropTypes.func,
    onKeyUp         : PropTypes.func,
    onKeyDown       : PropTypes.func,
    isLoading       : PropTypes.bool,
    isLocked        : PropTypes.bool,
    switchOptions   : PropTypes.arrayOf(
        PropTypes.shape({
            text        : PropTypes.string,
            colorType   : PropTypes.string
        })
    ),
    isDisabled      : PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.func
    ])
};

Switch.defaultProps = {
    label           : '',
    attrs           : {},
    onClick         : () => { /* onClick */ },
    onChange        : () => { /* onChange */ },
    onBlur          : () => { /* onBlur */ },
    onKeyUp         : () => { /* onKeyUp */ },
    onKeyDown       : () => { /* onKeyDown */ },
    isLoading       : false,
    isLocked        : false,
    switchOptions   : null,
    isDisabled      : false
};

export default Switch;