import AuthorizationContext     from 'auth/keycloak-instance';
import Product                  from './product'
import useTranslate             from 'app/common/hooks/translate';

const AllProducts = props => {
    const t         = useTranslate();
    const products  = AuthorizationContext.user.unAuthorizedProducts;
    
    const renderProductList = () => {
        return (
            products
            .map((item, index) => (
               
                <Product 
                    item                    = { item }
                    index                   = { index }
                    key                     = { 'p-' + index }
                    authorizedProducts      = { [] }
                    toggleApplicationLoader = { (url) => props.toggleApplicationLoader(url) }
                />
            ))
        )
    }

    return (
        <div className="product-selection-container">
            <h1>{ t('Meet_Our_Products') }</h1>
            <h2>{ t('Meet_Our_Products_Description') }</h2>

            <div className="product-list-container all-list">
                { renderProductList() }
            </div>
        </div>
    )
}

export default AllProducts