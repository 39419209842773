const products = [
    {
        name        : 'pos',
        text        : 'POS',
        url         : 'https://pos.simprasuite.com/?skipRedirection=true',
        desc        : 'Uçtan uca işletme yönetimi',
        landing     : 'https://simprasuite.com.tr/restoran-otomasyonu/pos-sistemi/'
    },
    {
        name        : 'inventory',
        text        : 'Inventory',
        url         : 'https://inventory.simprasuite.com/login?skipRedirection=true',
        desc        : 'Envanter yönetiminde akıllı çözüm',
        landing     : 'https://simprasuite.com.tr/restoran-otomasyonu/stok-takip-programi/'
    },
    {
        name        : 'link',
        text        : 'Link',
        url         : 'https://backoffice.simpralink.com/?skipRedirection=true',
        desc        : 'Ödemelerde geleceğin teknolojisi',
        landing     : 'https://simprasuite.com.tr/link/'
    },
    {
        name        : 'c&p',
        text        : 'Check & Place',
        url         : 'hhttps://www.checkandplace.com/?skipRedirection=true',
        desc        : 'Yeni nesil masa rezervasyon yönetimi',
        landing     : 'https://simprasuite.com.tr/restoran-rezervasyon-sistemi/'
    },
    /* {
        name        : 'loyalty',
        text        : 'Loyalty',
        url         : 'http://simprasuite.com',
        desc        : 'Sadakat yönetimi',
        landing     : 'https://simprasuite.com.tr/restoran-otomasyonu/musteri-sadakat-programi/'
    }, */
    {
        name        : 'pms',
        text        : 'PMS',
        url         : 'https://pms.simprasuite.com/?skipRedirection=true',
        desc        : 'Kusursuz otel yönetimi',
        landing     : 'https://simprasuite.com.tr/otel-otomasyonu/otel-yonetim-sistemi-pms/'
    }
]

export default products