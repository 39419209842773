
export const applicationTranslations =
    {
    "tr": {
        "Back_To_Dashboard": "Anasayfaya geri dön",
        "Profile_Settings": "Profil Ayarları",
        "Free_Simpra_Account": "Simpra hesabınızı ücretsiz kullanmaya başlayın",
        "Name": "Ad",
        "Surname": "Soyad",
        "Email": "E-posta",
        "Phone_Code": "Telefon Kodu",
        "Phone_Number": "Telefon Numarası",
        "Current_Password": "Mevcut Şifre",
        "New_Password": "Yeni Şifre",
        "Confirm_New_Password": "Yeni Şifreyi Doğrula",
        "Your_Account": "Hesabınızla işiniz bitti mi?",
        "Meet_Our_Products": "Ürünlerimizle tanışın",
        "Meet_Our_Products_Description": "Ürünlerimiz işletmenizin tüm ihtiyaçlarını karşılayacak",
        "Sign_Out_From_Browser": "Bu tarayıcıdan çıkış yapın",
        "Welcome": "Merhaba",
        "My_Permissions": "İzinlerim",
        "Campaign_Notifications": "Kampanya Bildirimleri",
        "Campaign_Notifications_Question": "Tüm kampanyalardan ve bildirimlerden haberdar olmak istiyor musunuz?",
        "Email_Permission": "Evet, e-posta aracılığıyla haberdar olmak isterim.",
        "SMS_Permission": "Evet, SMS aracılığıyla haberdar olmak isterim.",
        "Phone_Permission": "Evet, aranarak haberdar olmak isterim.",
        "Close": "KAPAT",
        "Save": "KAYDET",
        "Your_Products": "Ürünleriniz",
        "Your_Product_Message": "İşletmenizin tüm ihtiyaçlarını karşılayacak ürünlerimiz",
        "All_Other_Products": "Diğer Ürünlerimiz",
        "passwordRulesMessage": "Şifreniz şunları içermeli:",
        "passwordRules1": "En az 8 karakter olmalı",
        "passwordRules2": "En az 1 sayı içermeli",
        "passwordRules3": "En az 1 büyük harf içermeli",
        "passwordRules4": "En az 1 özel karakter içermeli",
        "Learn_More": "Daha fazla",
        "Verified": "DOĞRULANDI",
        "Not_Verified": "DOĞRULANMADI",
        "Change_My_Password": "Şifremi değiştir",
        "Change_My_Password_Content": "Şifrenizi değiştirmek için oturumunuzu kapatabilir ve giriş sayfasındaki “Şifremi unuttum” alanını kullanabilirsiniz" ,
        "Enter_Your_Surname": "Soyadınızı girin",
        "Enter_Your_Name": "Adınızı girin",
        "Enter_Your_Phone": "Telefon numaranızı girin",
        "slidertext1": "Simpra varsa kolaylık da var! Lezzetten ödün vermeden işleri kolaylaştıran hızlı ve etkili yazılım çözümleri Simpra’da.",
        "slidertext2": "Web sitesinden, telefondan veya Google'dan gelen rezervasyonlarınız tek platformda!",
        "slidertext3": "Simpra ile otel yönetiminin geleceği elinizin altında.",
        "slidertext4": "Simpra Inventory ile minimum maliyet, maksimum kazanç ve doğru seviyede stoklar!"
    },
    "en": {
        "Back_To_Dashboard": "Back to dashboard",
        "Profile_Settings": "Profile Settings",
        "Free_Simpra_Account": "Let's get started with your Simpra account free",
        "Name": "Name",
        "Surname": "Surname",
        "Email": "Email",
        "Phone_Code": "Phone Code",
        "Phone_Number": "Phone Number",
        "Current_Password": "Current Password",
        "New_Password": "New Password",
        "Confirm_New_Password": "Confirm New Password",
        "Your_Account": "Are you done with your account?",
        "Meet_Our_Products": "Meet the our all products",
        "Meet_Our_Products_Description": "Our products to meet all your needs for your business",
        "Sign_Out_From_Browser": "Sign out from this browser",
        "Welcome": "Welcome",
        "My_Permissions": "My Permissions",
        "Campaign_Notifications": "Campaign Notifications",
        "Campaign_Notifications_Question": "Do you want to be informed about all campaigns and notifications?",
        "Email_Permission": "Yes, I would like to be notified via email.",
        "SMS_Permission": "Yes, I would like to be informed via SMS.",
        "Phone_Permission": "Yes, I would like to be notified by calling.",
        "Close": "CLOSE",
        "Save": "SAVE",
        "Your_Products": "Your Products",
        "Your_Product_Message": "Our products to meet all your needs for your business",
        "All_Other_Products": "All Other Products",
        "passwordRulesMessage": "Your password must contain:",
        "passwordRules1": "At least 8 characters",
        "passwordRules2": "At least 1 numeric",
        "passwordRules3": "At least 1 uppercase letter",
        "passwordRules4": "At least 1 special character",
        "Learn_More": "Learn more",
        "Verified": "DOĞRULANDI",
        "Not_Verified": "DOĞRULANMADI",
        "Change_My_Password": "Change my password",
        "Change_My_Password_Content": "To change your password, you can log out and use the “Forgot password” field on the login page.",
        "Enter_Your_Name": "Enter your name",
        "Enter_Your_Surname": "Enter your surname",
        "Enter_Your_Phone": "Enter your phone",
        "slidertext1": "With Simpra, convenience is guaranteed! Simpra offers swift and efficient software solutions that enhance operations without sacrificing flavor.",
        "slidertext2": "Reservations from your website, phone, or Google all in one platform!",
        "slidertext3": "With Simpra, the future of hotel management is within your reach!",
        "slidertext4": "With Simpra Inventory, achieve minimum costs, maximum profits, and optimal stock levels!"
    },
}
