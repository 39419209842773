const Header = props => {
    const p = props;

    return (
        <div className="sc-chat-container-header">
            <div className="sc-title">Simpra AI</div>
            {
                !p.isChatEnded
                    ?   !p.isProductSelected
                        ?   null
                        :   <div className="end-chat-button simpra-button pink-shadow" onClick={ () => p.setChatBotState('isEndChatToggled', true) }>End Chat</div>
                    :   null

            }
            <div className="close-button" onClick={ () => p.toggleChatBot() }></div>
        </div>
    )
}

export default Header;