
import { useState }                 from 'react';
import useErrors                    from 'app/common/hooks/errors';
import useTranslate                 from 'app/common/hooks/translate';
import Validation                   from 'app/common/utils/validation';
import Textfield                    from 'app/common/uikit/textfield';
import AuthorizationContext         from 'auth/keycloak-instance';
import Phonepicker                  from 'app/common/uikit/phonepicker';

const ProfileSettings = _props => {
    const profile                           = AuthorizationContext.user.profile;
    const t                                 = useTranslate();
    const [ s, setState ]                   = useState({
        username        : profile?.username,
        firstName       : profile?.firstName,
        lastName        : profile?.lastName,
        email           : profile?.email,
        attributes      : profile?.attributes,
    })

   /*  const [ password, setPasswordState ]    = useState({
        currentPassword : null,
        newPassword     : null,
        confirmation    : null,
    }) */

    const [ showLoading, setShowLoading ]   = useState(false);

    const { 
        errors,
        setErrors,
        clearFormErrorData
    }                                       = useErrors();

    const updateFormData = (node, value) => {
        setState(prevState => ({
            ...prevState,
            [node]: value
        }))

        clearFormErrorData(node);
    }

    /* const updatePasswordFormData = (node, value) => {
        setPasswordState(prevState => ({
            ...prevState,
            [node]: value
        }));

        clearFormErrorData(node);
    } */

    const saveAccount = async () => {
        
        try {
            const validation = new Validation(t);
            //const validatePasswordData = password.newPassword || password.confirmation;
    
            const profileRules = {
                firstName           : {  required : true },
                lastName            : {  required : true },
                email               : {  required : true, type: 'email' },
            };
            
            /* const passwordRules = {
                currentPassword     : {  required : true },
                newPassword         : {  required : true },
                confirmation        : {  required : true },
            }; */
    
            const profileValidationResult = validation.validateData(profileRules, s);
            
            /* const passwordValidationResult = validatePasswordData
                                                ? validation.validateData(passwordRules, password)
                                                : { valid: true }; */
    
    
            if (!profileValidationResult.valid /* || !passwordValidationResult.valid */) {
                let errors = {};
                if (!profileValidationResult.valid)
                    errors = profileValidationResult.fields;
                
                /* if (!passwordValidationResult.valid)
                    errors = { ...errors, ...passwordValidationResult.fields }; */
    
                return setErrors(errors);
            }
    
            setShowLoading(true);

            await AuthorizationContext.user.updateProfile(s);

            /* if (validatePasswordData) {
                await AuthorizationContext.user.updatePassword({
                    type: 'password',
                    value: password.newPassword
                });
            } */

            setShowLoading(true);

        } catch(err) {

            console.log(err)

        } finally {

            setShowLoading(false);
        }
    }

    const changePhoneData = (phone, code) => {
        setState(prevState => ({
            ...prevState,
            attributes: {
                ...prevState.attributes,
                phoneNumber: phone,
                phoneCode: code,
            }
        }));
    }

    return (
        <>
            {
                showLoading
                    ?   <div id="application-loader"><div className="spinner-container"><div className="spinner" /></div></div>
                    :   null
            }
            <div className="profile-settings-content-container">
                <div className="profile-settings-content">
                    <div className="form-header">
                        <a href="/">{ t('Back_To_Dashboard') }</a>
                        <h1>{ t('Profile_Settings') }</h1>
                        <h2>{ t('Free_Simpra_Account') }</h2>
                    </div>
                    <form id="profile-settings-form">
                        <div className="form-row">
                            <div className="form-column">
                                <Textfield
                                    attrs       = {{
                                        id: 'name',
                                        name: 'name',
                                        tabIndex: 1
                                    }}
                                    label       = { t('Name') }
                                    onChange    = { value => updateFormData('firstName', value )}
                                    value       = { s.firstName }
                                    fieldName   = { 'firstName' }
                                    errors      = { errors }
                                    placeholder = { t('Enter_Your_Name') }
                                />
                            </div>
                            <div className="form-column">
                                <Textfield
                                    attrs       = {{
                                        id: 'surname',
                                        name: 'surname',
                                        tabIndex: 2
                                    }}
                                    label       = { t('Surname') }
                                    onChange    = { value => updateFormData('lastName', value )}
                                    value       = { s.lastName }
                                    fieldName   = { 'lastName' }
                                    errors      = { errors }
                                    placeholder = { t('Enter_Your_Surname') }
                                />
                            </div>
                        </div>
                        <div className="form-row email-row">
                            <Textfield
                                attrs       = {{
                                    id: 'email',
                                    name: 'email',
                                    tabIndex: 3,
                                    disabled: 'disabled'
                                }}
                                label       = { t('Email') }
                                value       = { s.email }
                                fieldName   = { 'email' }
                                errors      = { errors }
                            />
                            {
                                profile.emailVerified
                                    ?   <div className="simpra-badge success absolute">{ t('Verified') }</div>
                                    :   <div className="simpra-badge danger absolute">{ t('Not_Verified') }</div>
                            }
                        </div>
                        <div className="form-row">
                            <Phonepicker  
                                code        = { s?.attributes?.phoneCode?.[0] || '' }
                                number      = { s?.attributes?.phoneNumber?.[0] || '' }
                                onChange    = { ({ phone, code }) => changePhoneData(phone, code) }
                            />                            
                        </div>
                        <div className="form-row f-wrap">
                            <div className="password-change-reminder">
                                <h1>{ t('Change_My_Password') }</h1>
                                { t('Change_My_Password_Content') }
                            </div>
                        </div>
                        {/* <div className="form-row f-wrap">
                            <span className="rules">{ t("passwordRulesMessage") }</span>
                            <ul className="password-rules-container">
                                <li>{ t("passwordRules1") }</li>
                                <li>{ t("passwordRules2") }</li>
                                <li>{ t("passwordRules3") }</li>
                                <li>{ t("passwordRules4") }</li>
                            </ul>                         
                        </div>
                        <div className="form-row">
                            <Textfield
                                attrs       = {{
                                    id: 'currentPassword',
                                    name: 'currentPassword',
                                    tabIndex: 6,
                                    type: 'password',
                                    autoComplete: 'chrome-off'
                                }}
                                label       = { t('Current_Password') }
                                value       = { password.currentPassword }
                                fieldName   = { 'currentPassword' }
                                onChange    = { value => updatePasswordFormData('currentPassword', value )}
                                errors      = { errors }
                            />
                        </div>
                        <div className="form-row">
                            <div className="form-column">
                                <Textfield
                                    attrs       = {{
                                        id: 'newPassword',
                                        name: 'newPassword',
                                        tabIndex: 6,
                                        type: 'password',
                                        autoComplete: 'chrome-off'
                                    }}
                                    label       = { t('New_Password') }
                                    value       = { password.newPassword }
                                    fieldName   = { 'newPassword' }
                                    onChange    = { value => updatePasswordFormData('newPassword', value )}
                                    errors      = { errors }
                                />
                            </div>
                            <div className="form-column">
                                <Textfield
                                    attrs       = {{
                                        id: 'confirmation',
                                        name: 'confirmation',
                                        tabIndex: 7,
                                        type: 'password',
                                        autoComplete: 'chrome-off'
                                    }}
                                    label       = { t('Confirm_New_Password') }
                                    value       = { password.confirmation }
                                    fieldName   = { 'newPassword' }
                                    onChange    = { value => updatePasswordFormData('confirmation', value )}
                                    errors      = { errors }
                                />
                            </div>
                        </div> */}
                        <div className="form-sub-content">
                            <div className="sub-content-header">
                            { t('Your_Account') }
                            </div>
                            <div className="sign-out">
                                <div tabIndex="9" className="link" onClick={ () => AuthorizationContext.logout() }>{ t('Sign_Out_From_Browser') }</div>
                            </div>
                            <div className="send-form">
                                <input tabIndex="10" type="button" value="SAVE" onClick={ () => saveAccount() } className="simpra-button pink-shadow" disabled={ showLoading ? 'disabled' : '' } />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ProfileSettings;