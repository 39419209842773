import { useState, useEffect }  from 'react';
import {
    WelcomeText,
    ChatContainer,
    Header,
    Messages,
    Input,
    EndChat
}                               from './components';
import { 
    useSession, 
    useSelectProduct,
    usePost ,
    useEndChat
}                               from './services/query';

const Chatbot = props => {
    const p                 = props;
    const [ s, setState ]   = useState({
        isToggled               : false,
        isProductSelected       : false,
        isFirstResponseSent     : false,
        isEndChatToggled        : false,
        lockInput               : false,
        skipAnimation           : false,
        sessionId               : null,
        sourceProjectId         : null,
        isChatEnded             : false,
    })

    const [ endChatState, setEndChatState ] = useState({
        rating                  : null,
        comment                 : null,
    })

    const [ messages, setMessages ] = useState([
        {
            type: 'response',
            message: `Hello ${p.userData.profile.firstName}! Ask me anything about the Simpra Product of your choice and I will find an answer.`
        }
    ]);

    const session           = useSession({}, s.isToggled);
    const productSelection  = useSelectProduct();
    const messageSender     = usePost();
    const endChat           = useEndChat();


    const postMessage = (type, message) => {
        const mList = [ ...messages ];
        mList.push({ type, message });

        setMessages(mList);
    }

    const toggleChatBot = () => {
        if (s.isChatEnded) {
            setMessages([]);

            setState({
                isToggled               : false,
                isProductSelected       : false,
                isFirstResponseSent     : false,
                isEndChatToggled        : false,
                lockInput               : false,
                skipAnimation           : false,
                sessionId               : null,
                sourceProjectId         : null,
                isChatEnded             : false,
            });

            setEndChatState({
                rating                  : null,
                comment                 : null,
            })
        } else {
            setState( prevState => ({
                ...prevState,
                isToggled: !prevState.isToggled
            }))
        }
    }

    const selectProduct = project => {
        if (project) {
            setState(prevState => ({
                ...prevState,
                isProductSelected: true
            }));
    
            productSelection.mutate({
                sourceProjectId: project.id
            });
        }
    }

    const sendFirstMessage = () => {
        setMessages([
            {
                type: 'response',
                message: `Hello ${p.userData.profile.firstName}! Ask me anything about the Simpra Product of your choice and I will find an answer.`
            },
            {
                type: 'product'
            },
        ]);

        setState(prevState => ({
            ...prevState,
            isFirstResponseSent: true
        }))
    }

    const refreshChatBot = () => {
        setState({
            isToggled               : true,
            isProductSelected       : false,
            isFirstResponseSent     : false,
            isEndChatToggled        : false,
            lockInput               : false,
            skipAnimation           : false,
            sessionId               : null,
            sourceProjectId         : null,
            isChatEnded             : false,
        });
        
        setMessages([]);

        setTimeout(() => {
            sendFirstMessage();
        }, 1000)
    }

    const sendMessage = message => {
        messageSender.mutate(message);
        postMessage('message', message);

        setState( prevState => ({
            ...prevState,
            lockInput: true
        }))
    }

    const scrollToBottom = () => {
        const mContainer        = document.getElementsByClassName('sc-chat-container-messages-p')[0];
        mContainer.scrollTop    = mContainer.scrollHeight;
    }

    const createThreadHistory = history => {
        return history.map((item, i) => ({
            type: item.role === 0 ? 'message' : 'response',
            message: item.message
        }))
    }

    const closeChat = sessionId => {
        endChat.mutate({
            sessionId,
            ...endChatState
        })
    }

    const setEndChatStateModel = (key, value) => {
        setEndChatState(prevState => ({
            ...prevState,
            [key]: value
        }))
    }

    const setChatBotState = (key, value) => {
        setState(prevState => ({
            ...prevState,
            [key]: value
        }))
    }

    const toggledPromptAction = () => {
        if (session.data) {
            if (session.data?.value?.sourceProjectId) {
                setMessages([
                    {
                        type: 'response',
                        message: `Hello ${p.userData.profile.firstName}! Ask me anything about the Simpra Product of your choice and I will find an answer.`
                    },
                    {
                        type: 'product'
                    },
                    ...createThreadHistory(session.data.value.history)
                ]);
                
                setState( prevState => ({
                    ...prevState,
                    sourceProjectId     : session.data.value.sourceProjectId,
                    isProductSelected   : true,
                    sessionId           : session.data.id,
                    isFirstResponseSent : true,
                    skipAnimation       : true
                }));

                setTimeout(() => {
                    setState( prevState => ({
                        ...prevState,
                        skipAnimation       : false
                    }));
                }, 1000)
            } else {
                if (s.isToggled) {
                    sendFirstMessage();
                }
            }
        }        
    }

    useEffect(() => {
        if (s.isProductSelected && productSelection.isSuccess) {
            postMessage('response', 'Sure, ask your question about the Simpra Inventory product.');
        }
    }, [ productSelection.isSuccess ]) // eslint-disable-line

    useEffect(() => {
        if (endChat.isSuccess) {
            setChatBotState('isChatEnded', true);
        }
    }, [ endChat.isSuccess ])

    useEffect(() => {
        toggledPromptAction();
    }, [ session.data ]) // eslint-disable-line

    useEffect(() => {
        if (messageSender.data) {
            postMessage('response', messageSender.data.value.answer);
            scrollToBottom();
            setState( prevState => ({
                ...prevState,
                lockInput: false
            }))
        }
    }, [ messageSender.data ]) // eslint-disable-line

    return (
        <div id="simpra-chatbot">
            <ChatContainer
                isToggled               = { s.isToggled }
            >
                <Header
                    toggleChatBot       = { () => toggleChatBot() }
                    refreshChatBot      = { () => refreshChatBot() }
                    setChatBotState     = { (key, value) => setChatBotState(key, value) }
                    isFirstResponseSent = { s.isFirstResponseSent }
                    isProductSelected   = { s.isProductSelected }
                    isChatEnded         = { s.isChatEnded }
                />
                <Messages 
                    selectProduct       = { id => selectProduct(id) }
                    messages            = { messages }
                    skipAnimation       = { s.skipAnimation }
                    scrollToBottom      = { () => scrollToBottom() }
                    isProductSelected   = { s.isProductSelected }
                    sourceProjectId     = { s.sourceProjectId }
                />
                <Input 
                    sendMessage         = { m => sendMessage(m) }
                    isDisable           = { !s.isProductSelected || messageSender.isPending || s.lockInput }
                />
                <EndChat
                    setEndChatStateModel= { (key, value) => setEndChatStateModel(key, value) }
                    setChatBotState     = { (key, value) => setChatBotState(key, value) }
                    isEndChatToggled    = { s.isEndChatToggled }
                    isChatEnded         = { s.isChatEnded }
                    endChatState        = { endChatState }
                    closeChat           = { () => closeChat() }
                    firstName           = { p.userData.profile.firstName }
                />
            </ChatContainer>
            <div className="welcome-button">
                <button className="simpra-button pink-shadow chatbot-button" onClick={ () => toggleChatBot() }>HELLO!</button>
                <WelcomeText 
                    firstName           = { p.userData.profile.firstName }
                />
            </div>
        </div>
    )
}

export default Chatbot;