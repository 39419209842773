const ApplicationContent = props => {

    return (
        <div className={ `application-content-container${props.screenMode ? ' full-screen' : '' }` }>
            <div className="content-wrapper">
                { props.children }
            </div>
        </div>
    )
}

export default ApplicationContent