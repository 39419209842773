const countries = [
      {
        name: 'Turkey',
        code: 'TR',
        phoneCode: 90,
        maskFormat: '(###) ### ## ##',
        pattern: {}
      },
      {
        name: 'Afghanistan',
        code: 'AF',
        phoneCode: 93,
        maskFormat: '## ### ####',
        pattern: {}
      },
      {
        name: 'Åland Islands',
        code: 'AX',
        phoneCode: 358,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Albania',
        code: 'AL',
        phoneCode: 355,
        maskFormat: '# ### ####',
        pattern: {}
      },
      {
        name: 'Algeria',
        code: 'DZ',
        phoneCode: 213,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'American Samoa',
        code: 'AS',
        phoneCode: 1,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Andorra',
        code: 'AD',
        phoneCode: 376,
        maskFormat: '### ###',
        pattern: {}
      },
      {
        name: 'Angola',
        code: 'AO',
        phoneCode: 244,
        maskFormat: '### ### ###',
        pattern: {}
      },
      {
        name: 'Anguilla',
        code: 'AI',
        phoneCode: 1,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Antigua & Barbuda',
        code: 'AG',
        phoneCode: 1,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Argentina',
        code: 'AR',
        phoneCode: 54,
        maskFormat: '(##) ########',
        pattern: '/^((d{2}))s(d{8})/'
      },
      {
        name: 'Armenia',
        code: 'AM',
        phoneCode: 374,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Aruba',
        code: 'AW',
        phoneCode: 297,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Australia',
        code: 'AU',
        phoneCode: 61,
        maskFormat: '(##) ### ####',
        pattern: {}
      },
      {
        name: 'Austria',
        code: 'AT',
        phoneCode: 43,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Azerbaijan',
        code: 'AZ',
        phoneCode: 994,
        maskFormat: '(##) ### ## ##',
        pattern: {}
      },
      {
        name: 'Bahamas',
        code: 'BS',
        phoneCode: 1,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Bahrain',
        code: 'BH',
        phoneCode: 973,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Bangladesh',
        code: 'BD',
        phoneCode: 880,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Barbados',
        code: 'BB',
        phoneCode: 1,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Belarus',
        code: 'BY',
        phoneCode: 375,
        maskFormat: '(##) ### ## ##',
        pattern: {}
      },
      {
        name: 'Belgium',
        code: 'BE',
        phoneCode: 32,
        maskFormat: '### ## ## ##',
        pattern: {}
      },
      {
        name: 'Belize',
        code: 'BZ',
        phoneCode: 501,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Benin',
        code: 'BJ',
        phoneCode: 229,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Bermuda',
        code: 'BM',
        phoneCode: 1,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Bhutan',
        code: 'BT',
        phoneCode: 975,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Bolivia',
        code: 'BO',
        phoneCode: 591,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Bonaire, Sint Eustatius and Saba',
        code: 'BQ',
        phoneCode: 599,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Bosnia & Herzegovina',
        code: 'BA',
        phoneCode: 387,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Botswana',
        code: 'BW',
        phoneCode: 267,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Brazil',
        code: 'BR',
        phoneCode: 55,
        maskFormat: '(##) #########',
        pattern: {}
      },
      {
        name: 'British Indian Ocean Territory',
        code: 'IO',
        phoneCode: 246,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Iran',
        code: 'IR',
        phoneCode: 98,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'British Virgin Islands',
        code: 'VG',
        phoneCode: 1,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Brunei',
        code: 'BN',
        phoneCode: 673,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Bulgaria',
        code: 'BG',
        phoneCode: 359,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Burkina Faso',
        code: 'BF',
        phoneCode: 226,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Burundi',
        code: 'BI',
        phoneCode: 257,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Cambodia',
        code: 'KH',
        phoneCode: 855,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Cameroon',
        code: 'CM',
        phoneCode: 237,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Canada',
        code: 'CA',
        phoneCode: 1,
        maskFormat: '(###) ### ####',
        pattern: {}
      },
      {
        name: 'Cape Verde',
        code: 'CV',
        phoneCode: 238,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Cayman Islands',
        code: 'KY',
        phoneCode: 1,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'North Korea',
        code: 'KP',
        phoneCode: 850,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Central African Republic',
        code: 'CF',
        phoneCode: 236,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Chad',
        code: 'TD',
        phoneCode: 235,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Chile',
        code: 'CL',
        phoneCode: 56,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'China',
        code: 'CN',
        phoneCode: 86,
        maskFormat: '## #########',
        pattern: {}
      },
      {
        name: 'Christmas Island',
        code: 'CX',
        phoneCode: 61,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Cocos (Keeling) Islands',
        code: 'CC',
        phoneCode: 61,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Colombia',
        code: 'CO',
        phoneCode: 57,
        maskFormat: '### ### ####',
        pattern: {}
      },
      {
        name: 'Comoros',
        code: 'KM',
        phoneCode: 269,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Congo',
        code: 'CG',
        phoneCode: 242,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Cook Islands',
        code: 'CK',
        phoneCode: 682,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Costa Rica',
        code: 'CR',
        phoneCode: 506,
        maskFormat: '#### ####',
        pattern: {}
      },
      {
        name: 'Côte d’Ivoire',
        code: 'CI',
        phoneCode: 225,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Croatia',
        code: 'HR',
        phoneCode: 385,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Cuba',
        code: 'CU',
        phoneCode: 53,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Curaçao',
        code: 'CW',
        phoneCode: 599,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Cyprus',
        code: 'CY',
        phoneCode: 357,
        maskFormat: '## ######',
        pattern: {}
      },
      {
        name: 'Czechia',
        code: 'CZ',
        phoneCode: 420,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Democratic Republic of the Congo',
        code: 'CD',
        phoneCode: 243,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Denmark',
        code: 'DK',
        phoneCode: 45,
        maskFormat: '## ## ## ##',
        pattern: {}
      },
      {
        name: 'Djibouti',
        code: 'DJ',
        phoneCode: 253,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Dominica',
        code: 'DM',
        phoneCode: 1,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Dominican Republic',
        code: 'DO',
        phoneCode: 1,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Ecuador',
        code: 'EC',
        phoneCode: 593,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Egypt',
        code: 'EG',
        phoneCode: 20,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'El Salvador',
        code: 'SV',
        phoneCode: 503,
        maskFormat: '#### ####',
        pattern: {}
      },
      {
        name: 'Equatorial Guinea',
        code: 'GQ',
        phoneCode: 240,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Eritrea',
        code: 'ER',
        phoneCode: 291,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Estonia',
        code: 'EE',
        phoneCode: 372,
        maskFormat: '#### ######',
        pattern: {}
      },
      {
        name: 'Eswatini',
        code: 'SZ',
        phoneCode: 268,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Ethiopia',
        code: 'ET',
        phoneCode: 251,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Falkland Islands (Islas Malvinas)',
        code: 'FK',
        phoneCode: 500,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Faroe Islands',
        code: 'FO',
        phoneCode: 298,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Fiji',
        code: 'FJ',
        phoneCode: 679,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Finland',
        code: 'FI',
        phoneCode: 358,
        maskFormat: '## ### ## ##',
        pattern: {}
      },
      {
        name: 'France',
        code: 'FR',
        phoneCode: 33,
        maskFormat: '# ## ## ## ##',
        pattern: {}
      },
      {
        name: 'French Guiana',
        code: 'GF',
        phoneCode: 594,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'French Polynesia',
        code: 'PF',
        phoneCode: 689,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Gabon',
        code: 'GA',
        phoneCode: 241,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Gambia',
        code: 'GM',
        phoneCode: 220,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Georgia',
        code: 'GE',
        phoneCode: 995,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Germany',
        code: 'DE',
        phoneCode: 49,
        maskFormat: '#### ########',
        pattern: {}
      },
      {
        name: 'Ghana',
        code: 'GH',
        phoneCode: 233,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Gibraltar',
        code: 'GI',
        phoneCode: 350,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Greece',
        code: 'GR',
        phoneCode: 30,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Greenland',
        code: 'GL',
        phoneCode: 299,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Grenada',
        code: 'GD',
        phoneCode: 1,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Guadeloupe',
        code: 'GP',
        phoneCode: 590,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Guam',
        code: 'GU',
        phoneCode: 1,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Guatemala',
        code: 'GT',
        phoneCode: 502,
        maskFormat: '#### ####',
        pattern: {}
      },
      {
        name: 'Guernsey',
        code: 'GG',
        phoneCode: 44,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Guinea',
        code: 'GN',
        phoneCode: 224,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Guinea-Bissau',
        code: 'GW',
        phoneCode: 245,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Guyana',
        code: 'GY',
        phoneCode: 592,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Haiti',
        code: 'HT',
        phoneCode: 509,
        maskFormat: '#### ####',
        pattern: {}
      },
      {
        name: 'Honduras',
        code: 'HN',
        phoneCode: 504,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Hong Kong',
        code: 'HK',
        phoneCode: 852,
        maskFormat: '#### ####',
        pattern: {}
      },
      {
        name: 'Hungary',
        code: 'HU',
        phoneCode: 36,
        maskFormat: '## ### ## ##',
        pattern: {}
      },
      {
        name: 'Iceland',
        code: 'IS',
        phoneCode: 354,
        maskFormat: '### ####',
        pattern: {}
      },
      {
        name: 'India',
        code: 'IN',
        phoneCode: 91,
        maskFormat: '##### #####',
        pattern: {}
      },
      {
        name: 'Indonesia',
        code: 'ID',
        phoneCode: 62,
        maskFormat: '### ########',
        pattern: {}
      },
      {
        name: 'Iraq',
        code: 'IQ',
        phoneCode: 964,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Ireland',
        code: 'IE',
        phoneCode: 353,
        maskFormat: '## #######',
        pattern: {}
      },
      {
        name: 'Isle of Man',
        code: 'IM',
        phoneCode: 44,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Israel',
        code: 'IL',
        phoneCode: 972,
        maskFormat: '### ### ####',
        pattern: {}
      },
      {
        name: 'Italy',
        code: 'IT',
        phoneCode: 39,
        maskFormat: '### #######',
        pattern: {}
      },
      {
        name: 'Jamaica',
        code: 'JM',
        phoneCode: 1,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Japan',
        code: 'JP',
        phoneCode: 81,
        maskFormat: '## #### ####',
        pattern: {}
      },
      {
        name: 'Jersey',
        code: 'JE',
        phoneCode: 44,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Jordan',
        code: 'JO',
        phoneCode: 962,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Kazakhstan',
        code: 'KZ',
        phoneCode: 7,
        maskFormat: '### ### ## ##',
        pattern: {}
      },
      {
        name: 'Kenya',
        code: 'KE',
        phoneCode: 254,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Kiribati',
        code: 'KI',
        phoneCode: 686,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Kosovo',
        code: 'XK',
        phoneCode: 383,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Kuwait',
        code: 'KW',
        phoneCode: 965,
        maskFormat: '#### ####',
        pattern: {}
      },
      {
        name: 'Kyrgyzstan',
        code: 'KG',
        phoneCode: 996,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Laos',
        code: 'LA',
        phoneCode: 856,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Latvia',
        code: 'LV',
        phoneCode: 371,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Lebanon',
        code: 'LB',
        phoneCode: 961,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Lesotho',
        code: 'LS',
        phoneCode: 266,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Liberia',
        code: 'LR',
        phoneCode: 231,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Libya',
        code: 'LY',
        phoneCode: 218,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Liechtenstein',
        code: 'LI',
        phoneCode: 423,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Lithuania',
        code: 'LT',
        phoneCode: 370,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Luxembourg',
        code: 'LU',
        phoneCode: 352,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Macau',
        code: 'MO',
        phoneCode: 853,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'North Macedonia',
        code: 'MK',
        phoneCode: 389,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Madagascar',
        code: 'MG',
        phoneCode: 261,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Malawi',
        code: 'MW',
        phoneCode: 265,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Malaysia',
        code: 'MY',
        phoneCode: 60,
        maskFormat: '## #### ####',
        pattern: {}
      },
      {
        name: 'Maldives',
        code: 'MV',
        phoneCode: 960,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Mali',
        code: 'ML',
        phoneCode: 223,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Malta',
        code: 'MT',
        phoneCode: 356,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Marshall Islands',
        code: 'MH',
        phoneCode: 692,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Martinique',
        code: 'MQ',
        phoneCode: 596,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Mauritania',
        code: 'MR',
        phoneCode: 222,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Mauritius',
        code: 'MU',
        phoneCode: 230,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Mayotte',
        code: 'YT',
        phoneCode: 262,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Mexico',
        code: 'MX',
        phoneCode: 52,
        maskFormat: '### ### ####',
        pattern: {}
      },
      {
        name: 'Micronesia',
        code: 'FM',
        phoneCode: 691,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Moldova',
        code: 'MD',
        phoneCode: 373,
        maskFormat: '(##) ## ## ##',
        pattern: {}
      },
      {
        name: 'Monaco',
        code: 'MC',
        phoneCode: 377,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Mongolia',
        code: 'MN',
        phoneCode: 976,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Montenegro',
        code: 'ME',
        phoneCode: 382,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Montserrat',
        code: 'MS',
        phoneCode: 1,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Morocco',
        code: 'MA',
        phoneCode: 212,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Mozambique',
        code: 'MZ',
        phoneCode: 258,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Myanmar',
        code: 'MM',
        phoneCode: 95,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Namibia',
        code: 'NA',
        phoneCode: 264,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Nauru',
        code: 'NR',
        phoneCode: 674,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Nepal',
        code: 'NP',
        phoneCode: 977,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Netherlands',
        code: 'NL',
        phoneCode: 31,
        maskFormat: '## ########',
        pattern: {}
      },
      {
        name: 'New Caledonia',
        code: 'NC',
        phoneCode: 687,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'New Zealand',
        code: 'NZ',
        phoneCode: 64,
        maskFormat: '### ### ####',
        pattern: {}
      },
      {
        name: 'Nicaragua',
        code: 'NI',
        phoneCode: 505,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Niger',
        code: 'NE',
        phoneCode: 227,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Nigeria',
        code: 'NG',
        phoneCode: 234,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Niue',
        code: 'NU',
        phoneCode: 683,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Norfolk Island',
        code: 'NF',
        phoneCode: 672,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Northern Mariana Islands',
        code: 'MP',
        phoneCode: 1,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Norway',
        code: 'NO',
        phoneCode: 47,
        maskFormat: '### ## ###',
        pattern: {}
      },
      {
        name: 'Oman',
        code: 'OM',
        phoneCode: 968,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Pakistan',
        code: 'PK',
        phoneCode: 92,
        maskFormat: '### #######',
        pattern: {}
      },
      {
        name: 'Palau',
        code: 'PW',
        phoneCode: 680,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Palestinian Territories',
        code: 'PS',
        phoneCode: 970,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Panama',
        code: 'PA',
        phoneCode: 507,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Papua New Guinea',
        code: 'PG',
        phoneCode: 675,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Paraguay',
        code: 'PY',
        phoneCode: 595,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Peru',
        code: 'PE',
        phoneCode: 51,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Philippines',
        code: 'PH',
        phoneCode: 63,
        maskFormat: '#### #######',
        pattern: {}
      },
      {
        name: 'Pitcairn Islands',
        code: 'PN',
        phoneCode: 64,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Poland',
        code: 'PL',
        phoneCode: 48,
        maskFormat: '### ### ###',
        pattern: {}
      },
      {
        name: 'Portugal',
        code: 'PT',
        phoneCode: 351,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Puerto Rico',
        code: 'PR',
        phoneCode: 1,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Qatar',
        code: 'QA',
        phoneCode: 974,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Réunion',
        code: 'RE',
        phoneCode: 262,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Romania',
        code: 'RO',
        phoneCode: 40,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Russia',
        code: 'RU',
        phoneCode: 7,
        maskFormat: '(###) ### ## ##',
        pattern: {}
      },
      {
        name: 'Rwanda',
        code: 'RW',
        phoneCode: 250,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Samoa',
        code: 'WS',
        phoneCode: 685,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'San Marino',
        code: 'SM',
        phoneCode: 378,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'São Tomé & Príncipe',
        code: 'ST',
        phoneCode: 239,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Syria',
        code: 'SY',
        phoneCode: 963,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Saudi Arabia',
        code: 'SA',
        phoneCode: 966,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Senegal',
        code: 'SN',
        phoneCode: 221,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Serbia',
        code: 'RS',
        phoneCode: 381,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Seychelles',
        code: 'SC',
        phoneCode: 248,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Sierra Leone',
        code: 'SL',
        phoneCode: 232,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Singapore',
        code: 'SG',
        phoneCode: 65,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Sint Maarten',
        code: 'SX',
        phoneCode: 1,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Slovakia',
        code: 'SK',
        phoneCode: 421,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Slovenia',
        code: 'SI',
        phoneCode: 386,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Solomon Islands',
        code: 'SB',
        phoneCode: 677,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Somalia',
        code: 'SO',
        phoneCode: 252,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'South Africa',
        code: 'ZA',
        phoneCode: 27,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'South Georgia & South Sandwich Islands',
        code: 'GS',
        phoneCode: 500,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'South Korea',
        code: 'KR',
        phoneCode: 82,
        maskFormat: '### #### ####',
        pattern: {}
      },
      {
        name: 'South Sudan',
        code: 'SS',
        phoneCode: 211,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Spain',
        code: 'ES',
        phoneCode: 34,
        maskFormat: '### ### ###',
        pattern: {}
      },
      {
        name: 'Sri Lanka',
        code: 'LK',
        phoneCode: 94,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'St. Barthélemy',
        code: 'BL',
        phoneCode: 590,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'St. Helena',
        code: 'SH',
        phoneCode: 290,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'St. Kitts & Nevis',
        code: 'KN',
        phoneCode: 1,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'St. Lucia',
        code: 'LC',
        phoneCode: 1,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'St. Martin',
        code: 'MF',
        phoneCode: 590,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'St. Pierre & Miquelon',
        code: 'PM',
        phoneCode: 508,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'St. Vincent & Grenadines',
        code: 'VC',
        phoneCode: 1,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Sudan',
        code: 'SD',
        phoneCode: 249,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Suriname',
        code: 'SR',
        phoneCode: 597,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Svalbard & Jan Mayen',
        code: 'SJ',
        phoneCode: 47,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Sweden',
        code: 'SE',
        phoneCode: 46,
        maskFormat: '(###) ### ###',
        pattern: {}
      },
      {
        name: 'Switzerland',
        code: 'CH',
        phoneCode: 41,
        maskFormat: '## ### ## ##',
        pattern: {}
      },
      {
        name: 'Taiwan',
        code: 'TW',
        phoneCode: 886,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Tajikistan',
        code: 'TJ',
        phoneCode: 992,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Tanzania',
        code: 'TZ',
        phoneCode: 255,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Thailand',
        code: 'TH',
        phoneCode: 66,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Timor-Leste',
        code: 'TL',
        phoneCode: 670,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Togo',
        code: 'TG',
        phoneCode: 228,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Tokelau',
        code: 'TK',
        phoneCode: 690,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Tonga',
        code: 'TO',
        phoneCode: 676,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Trinidad & Tobago',
        code: 'TT',
        phoneCode: 1,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Tunisia',
        code: 'TN',
        phoneCode: 216,
        maskFormat: null,
        pattern: null
      },

      {
        name: 'Turkmenistan',
        code: 'TM',
        phoneCode: 993,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Turks & Caicos Islands',
        code: 'TC',
        phoneCode: 1,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Tuvalu',
        code: 'TV',
        phoneCode: 688,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'U.S. Virgin Islands',
        code: 'VI',
        phoneCode: 1,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Uganda',
        code: 'UG',
        phoneCode: 256,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Ukraine',
        code: 'UA',
        phoneCode: 380,
        maskFormat: '(##) ### ## ##',
        pattern: {}
      },
      {
        name: 'United Arab Emirates',
        code: 'AE',
        phoneCode: 971,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'United Kingdom',
        code: 'GB',
        phoneCode: 44,
        maskFormat: '#### ######',
        pattern: {}
      },
      {
        name: 'United States',
        code: 'US',
        phoneCode: 1,
        maskFormat: '(###) ###-####',
        pattern: null
      },
      {
        name: 'Uruguay',
        code: 'UY',
        phoneCode: 598,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Uzbekistan',
        code: 'UZ',
        phoneCode: 998,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Vanuatu',
        code: 'VU',
        phoneCode: 678,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Vatican City',
        code: 'VA',
        phoneCode: 379,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Venezuela',
        code: 'VE',
        phoneCode: 58,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Vietnam',
        code: 'VN',
        phoneCode: 84,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Wallis & Futuna',
        code: 'WF',
        phoneCode: 681,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Western Sahara',
        code: 'EH',
        phoneCode: 212,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Yemen',
        code: 'YE',
        phoneCode: 967,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Zambia',
        code: 'ZM',
        phoneCode: 260,
        maskFormat: null,
        pattern: null
      },
      {
        name: 'Zimbabwe',
        code: 'ZW',
        phoneCode: 263,
        maskFormat: null,
        pattern: null
      }
]

export default countries