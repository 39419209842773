import { useState, useEffect }  from 'react';

const ProductSelection = props => {
    const p                 = props;
    const [ s, setState ]   = useState({
        selected: null
    })

    useEffect(() => {
        if (p.sourceProjectId)
            setState({
                selected: { name: 'inv' }
            })
    }, []) // eslint-disable-line

    const selectProduct = project => {
        if (!p.isProductSelected) {
            setState({
                selected: project
            })

            p.selectProduct(project);
        }
    }

    const getClassByStatus = (project, id) => {
        return s.selected?.name === project  || p.sourceProjectId === id
                ? ' selected' 
                : s.selected?.name 
                    ? ' disabled' 
                    : '';
    }

    return (
        <div className="sc-chat-container-product-selection">
            <div className="ps-question">Which product do you have a question about? </div>
            <div className="p-list">
                <div onClick={ () => selectProduct(null) } className={ `p-list-item pos${getClassByStatus('pos')}`}>POS</div>
                <div onClick={ () => selectProduct({ name: 'inventory', id: '8e483365-4f88-46fc-815a-950a19028763'}) } className={ `p-list-item inventory${getClassByStatus('inventory', '8e483365-4f88-46fc-815a-950a19028763')}`}>Inventory</div>
                <div onClick={ () => selectProduct(null) } className={ `p-list-item link${getClassByStatus('link')}` }>Link</div>
                <div onClick={ () => selectProduct(null) } className={ `p-list-item checkandplace${getClassByStatus('checkandplace')}` }>Check & Place</div>
                <div onClick={ () => selectProduct(null) } className={ `p-list-item pms${getClassByStatus('pms')}`}>PMS</div>
            </div>
        </div>
    )
}

export default ProductSelection;