
import React                        from 'react';
import { 
    useSelector,
    shallowEqual 
}                                   from 'react-redux';
import { applicationTranslations }  from "app/common/constants/translations";

const useTranslate = () => {
    const redux         = useSelector( state => ({
            displayLanguage                : state.provider.displayLanguage
        }), shallowEqual
    );
    
    return React.useCallback((key, lang = null) => {
        const availableLanguages    = [ 'tr', 'en' ];
        const defaultLanguage       = 'tr';

        let targetLanguage = lang || redux.displayLanguage;
        
        targetLanguage = availableLanguages?.includes(targetLanguage)
                            ? targetLanguage
                            : defaultLanguage;

        return  applicationTranslations?.[targetLanguage]?.[key] || key
    }, [ redux.displayLanguage ])
}

export default useTranslate;