import React            from 'react';

class Base extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };
    }

    render(){
        let { 
            onChange,
            onClick,
            onFocus,
            onBlur,
            onKeyUp,
            onKeyDown,
            onKeyPress,
            value
        } = this.props;
        return(
            <input
                ref         = 'INPUT'
                type        = "checkbox"
                onChange    = { onChange }
                onClick     = { onClick }
                onFocus     = { onFocus }
                onBlur      = { onBlur }
                onKeyUp     = { onKeyUp }
                onKeyDown   = { onKeyDown }
                onKeyPress  = { onKeyPress }
                value       = { value }
            />
        )
    }
}

export default Base;