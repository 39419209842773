import React            from 'react';

class Container extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };
    }

    render(){
        let { active, className, isFocused, children, attrs } = this.props;
        return(
            <div 
                className       = { className }
                data-value      = { active }
                data-focused    = { isFocused }
                { ...attrs }
            >
                { children }
            </div>
        )
    }
}

export default Container;