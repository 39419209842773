import { useState }             from 'react';
import AuthorizationContext     from 'auth/keycloak-instance';
import useTranslate             from 'app/common/hooks/translate';
import Switch                   from 'app/common/uikit/switch';
import Phonepicker              from 'app/common/uikit/phonepicker';
import Button                   from 'app/common/uikit/button';
import Validation               from 'app/common/utils/validation';
import useErrors                from 'app/common/hooks/errors';
import SDate                    from 'app/common/utils/date-api';

const Permissions = props => {
    const t                                 = useTranslate();
    const p                                 = props;

    const [ permissions, setPermissions ]   = useState({
        email           : AuthorizationContext.user.profile.email,
        iys_allowSms                    : AuthorizationContext.user.profile?.attributes?.iys_allowSms?.[0] === 'true' || false,
        iys_allowPhone                  : AuthorizationContext.user.profile?.attributes?.iys_allowPhone?.[0] === 'true' || false,
        iys_allowEmail                  : AuthorizationContext.user.profile?.attributes?.iys_allowEmail?.[0] === 'true' || false,

        iys_smsPermissionChangeDate     : AuthorizationContext.user.profile?.attributes?.iys_smsPermissionChangeDate?.[0],
        iys_phonePermissionChangeDate   : AuthorizationContext.user.profile?.attributes?.iys_phonePermissionChangeDate?.[0],
        iys_emailPermissionChangeDate   : AuthorizationContext.user.profile?.attributes?.iys_emailPermissionChangeDate?.[0],
    })

    const [ phone, setPhone ]               = useState({
        code            : AuthorizationContext.user.profile?.attributes?.phoneCode?.[0] || '90',
        number          : AuthorizationContext.user.profile?.attributes?.phoneNumber?.[0] || null,
    })

    const [ showLoading, setShowLoading ]   = useState(false);

    const { 
        errors,
        setErrors,
        //clearFormErrorData
    }                                       = useErrors();

    const changePermissionStatus = (key, value) => {
        const dateObjects = {
            iys_allowSms    : 'iys_smsPermissionChangeDate',
            iys_allowPhone  : 'iys_phonePermissionChangeDate',
            iys_allowEmail  : 'iys_emailPermissionChangeDate',
        }

        setPermissions(prevState => ({
            ...prevState,
            [key]: value,
            [dateObjects[key]]: SDate.format(new Date(), 'yyyy/MM/dd hh:Mi')
        }))
    }

    const changePhoneData = (phone, code) => {
        setPhone(_prevState => ({
            number      : phone,
            code        : code
        }))
    }

    const savePermissions = async () => {
        try {
            setShowLoading(true);

            const validation = new Validation(t);
                            
            const payload = {
                ...AuthorizationContext.user.profile,
                attributes: {
                    ...AuthorizationContext.user.profile.attributes,
                    ...permissions,
                }
            } 

            if (!AuthorizationContext.user.profile?.attributes?.phoneNumber && (permissions.iys_allowPhone || permissions.iys_allowSms)) {
                
                payload.attributes = {
                    ...payload.attributes,
                    phoneCode       : phone.code,
                    phoneNumber     : phone.number
                }
                
                const phoneRules = {
                    number      : { required: true, minLength: 1 },
                    code        : { required: true, minLength: 1 },
                };

                const phoneValidationResult = validation.validateData(phoneRules, phone);

                if (!phoneValidationResult.valid) {
                    return setErrors(phoneValidationResult.fields);
                }
            }
            /** 
             * @deprecate 
            await AuthorizationContext.user.updateIYSPermissions(permissions);
            */
            await AuthorizationContext.user.updateProfile(payload);

            p.closePermissionModal();

        } catch(err) {
            console.log(err)
        } finally {
            setShowLoading(false);
        }
    }

    const renderPhonePickerInput = () => {
        return (
            (permissions.iys_allowSms || permissions.iys_allowPhone)
                ?   <div className="input-container">
                        <div className="form-row">
                            <Phonepicker  
                                code        = { AuthorizationContext.user.profile?.attributes?.phoneCode?.[0] || phone.code }
                                number      = { AuthorizationContext.user.profile?.attributes?.phoneNumber?.[0] || phone.number }
                                isDisabled  = { AuthorizationContext.user.profile?.attributes?.phoneNumber }
                                onChange    = { ({ phone, code }) => changePhoneData(phone, code) }
                                errors      = { errors }
                            />
                        </div>
                    </div>
                :   null
        )
    }

    const renderEmailInput = () => {
        return permissions.iys_allowEmail
                ?   <div className="input-container">
                        <div className="simpra-input">
                            <input disabled tabIndex="1" id="name" className="simpra-input" name="email" defaultValue={ AuthorizationContext.user.profile.email } type="text" />          
                        </div>
                    </div>
                :   null
    }

    return (
        <>
            {
                showLoading
                    ?   <div id="application-loader"><div className="spinner-container"><div className="spinner" /></div></div>
                    :   null
            }        
            <div className="permissions-container">
                <div className="permissions-content-wrapper">
                    <div className="header">
                        <h1>{ t('Campaign_Notifications') }</h1>
                        <h2>{ t('Campaign_Notifications_Question') }</h2>
                    </div>
                    <div className="permission-content">
                        <div className="form-row fd-column">
                            <Switch
                                attrs       = {{
                                    name: 'allowEmail',
                                    id: 'allowEmail'
                                }}
                                label       = { t('Email_Permission') }
                                onChange    = { isChecked => changePermissionStatus('iys_allowEmail', isChecked) }
                                value       = { permissions.iys_allowEmail }
                            />
                            { renderEmailInput() }
                        </div>
                        <div className="form-row fd-column">
                            <Switch
                                attrs       = {{
                                    name: 'allowSms',
                                    id: 'allowSms'
                                }}
                                label       = { t('SMS_Permission') }
                                onChange    = { isChecked => changePermissionStatus('iys_allowSms', isChecked) }
                                value       = { permissions.iys_allowSms }
                            />

                        </div>
                        <div className="form-row flat fd-column">
                            <Switch
                                attrs       = {{
                                    name: 'allowPhone',
                                    id: 'allowPhone'
                                }}
                                label       = { t('Phone_Permission') }
                                onChange    = { isChecked => changePermissionStatus('iys_allowPhone', isChecked) }
                                value       = { permissions.iys_allowPhone }
                            />
                            { renderPhonePickerInput() }
                        </div>
                        <div className="form-row mt30 mb0">
                            <Button
                                label       = { t('Save') }
                                onClick     = { () => savePermissions() }
                                //isDisabled  = { isNotAnyPermission() }
                                full
                            />                            
                            {/* <div className="form-column">
                                <Button
                                    label       = { t('Close') }
                                    onClick     = { () => p.closePermissionModal() }
                                    full
                                    outlined
                                />
                            </div>
                            <div className="form-column">

                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Permissions