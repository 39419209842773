import { useState, useEffect }  from 'react';
import {
    WelcomeMessage,
    ProductList,
    AllProducts,
    Permissions
}                               from './components'
import Chatbot                  from './components/chatbot'
import AuthorizationContext     from 'auth/keycloak-instance';

const ProductSelection = _props => {

    const [ s, setState ]   = useState({
        showPermission: false
    })
    const [ showLoading, setShowLoading ]   = useState(false);

    useEffect(() => {
        if (!AuthorizationContext.user.profile.attributes.iys_allowPhone)
            setState({
                showPermission: true
            });
    }, [])

    const togglePermissionModal = () => {
        setState({
            showPermission: !s.showPermission
        });
    }

    const toggleApplicationLoader = url => {
        setShowLoading(!showLoading);
        window.location.href = url;
    }

    const renderProductListContent = () => {
        return (
            AuthorizationContext.user.authorizedProducts.length
                ?   <ProductList toggleApplicationLoader={ (url) => toggleApplicationLoader(url) } />
                :   <AllProducts toggleApplicationLoader={ (url) => toggleApplicationLoader(url) } />
        )
    } 

    const renderPermissions = () => {
        return s.showPermission
            ?   <Permissions closePermissionModal={ () => togglePermissionModal() } />
            :   null
    }

    return (
        <>
            {
                showLoading
                    ?   <div id="application-loader"><div className="spinner-container"><div className="spinner" /></div></div>
                    :   null
            }            
            <WelcomeMessage 
                openPermissionModal={ () => togglePermissionModal() }
            />
            { renderPermissions() }
            { renderProductListContent() }
            <Chatbot userData={ AuthorizationContext.user } />
        </>
    )
}

export default ProductSelection