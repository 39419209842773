import { useState, useEffect }      from 'react';
import useTranslate                 from 'app/common/hooks/translate';
let tt;

const LandingContent = props => {
    const t                                 = useTranslate();

    const [ s, setState ]                   = useState({
        slideIndex        : 0,
    })
    
    useEffect(() => {
        showSlides();
    }, [ s.slideIndex ])

    const plusSlides = n => {
        const newValue = s.slideIndex + n;

        setState({
            slideIndex: newValue < 0 ? 3 : newValue > 3 ? 0 : newValue
        })
    }

    const showSlides = (current = null) => {
        clearTimeout(tt);

        tt = setTimeout(() => {
            setState(prevState => ({
                slideIndex: prevState.slideIndex < 3 ? prevState.slideIndex + 1 : 0
            }))
        }, 7000)
    } 

    return (
        <div className="landing-content-container">
            <div className="slideshow-container">
                
                <div className="text" style={{ display: s.slideIndex === 0 ? 'block' : 'none' }}>{ t("slidertext1") }</div>
                <div className="text" style={{ display: s.slideIndex === 1 ? 'block' : 'none' }}>{ t("slidertext2") }</div>
                <div className="text" style={{ display: s.slideIndex === 2 ? 'block' : 'none' }}>{ t("slidertext3") }</div>
                <div className="text" style={{ display: s.slideIndex === 3 ? 'block' : 'none' }}>{ t("slidertext4") }</div>

                <div className="slider-overlay"></div>

                <div className="slide s-1 fade" style={{ display: s.slideIndex === 0 ? 'block' : 'none' }}></div>
                <div className="slide s-2 fade" style={{ display: s.slideIndex === 1 ? 'block' : 'none' }}></div>
                <div className="slide s-3 fade" style={{ display: s.slideIndex === 2 ? 'block' : 'none' }}></div>
                <div className="slide s-4 fade" style={{ display: s.slideIndex === 3 ? 'block' : 'none' }}></div>

                <div className="prev" onClick={ () => plusSlides(-1) }>&#10094;</div>
                <div className="next" onClick={ () => plusSlides(1) }>&#10095;</div>

            </div>
        </div>
    )
}

export default LandingContent