const EndChat = props => {
    const p                 = props;

    const hoverStar = ind => {
        return [ ...Array(5) ].map( (_, i) => {
            const el = document.getElementsByClassName('s' + i)[0];
            
            if (el) {
                if (i <= ind) {
                    el.classList.add('hovered');
                } else {
                    el.classList.remove('hovered');
                }
            }

            return true;
        })
    }

    const closeAllStars = isRemove => {
        Array.prototype.slice.call(document.getElementsByClassName('star')).map( item => {
            item.classList.remove('hovered');

            if (isRemove)
                item.classList.remove('selected');

            return true;
        })
    }

    const selectRate = e => {
        const rate = e.target.dataset.rate;

        Array.prototype.slice.call(document.getElementsByClassName('star')).map( item => {
            item.classList.remove('selected');

            return true;
        })

        const arr = [ ...Array(Number(rate)) ].map( (_, i) => {
            const el = document.getElementsByClassName('s' + String(i))[0];
            if (el) {
                el.classList.add('selected');
            }

            return true;
        })

        p.setEndChatStateModel('rating', rate);

        return arr;
    }

    const updateComment = e => {
        p.setEndChatStateModel('comment', e.target.value);
    }

    const toggleEndChat = () => {
        const inputElement = document.getElementsByClassName('comment-input')[0];
        inputElement.value = '';
        closeAllStars(true);
        p.setChatBotState('isEndChatToggled', false);
        p.setEndChatStateModel('comment', null);
        p.setEndChatStateModel('rating', null);
    }

    return (
        <div className={ `sc-chat-container-end-chat${p.isEndChatToggled ? ' toggled' : ''}` }>
            {
                !p.isChatEnded
                    ?   <>
                            <div className="sc-chat-container-end-chat-header">
                                <div className="sc-chat-container-end-chat-header-title">
                                    How is your conversation with Simpra AI?
                                </div>
                                <div className="sc-chat-container-end-chat-header-close-button" onClick={ () => toggleEndChat() }></div>
                            </div>
                            <div className="sc-chat-container-end-chat-rating" onMouseOut={ e => closeAllStars() }>
                                <div onMouseDown={ e => selectRate(e) } onMouseOver={ e => hoverStar(0) } className="star s0" data-rate="1"></div>
                                <div onMouseDown={ e => selectRate(e) } onMouseOver={ e => hoverStar(1) } className="star s1" data-rate="2"></div>
                                <div onMouseDown={ e => selectRate(e) } onMouseOver={ e => hoverStar(2) } className="star s2" data-rate="3"></div>
                                <div onMouseDown={ e => selectRate(e) } onMouseOver={ e => hoverStar(3) } className="star s3" data-rate="4"></div>
                                <div onMouseDown={ e => selectRate(e) } onMouseOver={ e => hoverStar(4) } className="star s4" data-rate="5"></div>
                            </div>
                            <div className="sc-chat-container-end-chat-comment">
                                <textarea className="comment-input" onInput={ e => updateComment(e) }/>
                            </div>
                            <div className="sc-chat-container-end-chat-footer">
                                <button className="simpra-button pink-shadow end-chat" onClick={ () => p.closeChat() }>END CHAT</button>
                                <button className="simpra-button pink-shadow send" onClick={ () => p.closeChat() } disabled={ !p.endChatState.rating && !p.endChatState.comment }>SEND</button>
                            </div>
                        </>
                    :   <div className="sc-chat-container-end-chat-result">
                            <div className="sc-chat-container-end-chat-result-salute">Thank you { p.firstName }!</div>
                            <div className="sc-chat-container-end-chat-rating">
                                <div className={ `star s0${p.endChatState.rating >= 1 ? ' selected' : ''}` }></div>
                                <div className={ `star s1${p.endChatState.rating >= 2 ? ' selected' : ''}` }></div>
                                <div className={ `star s2${p.endChatState.rating >= 3 ? ' selected' : ''}` }></div>
                                <div className={ `star s3${p.endChatState.rating >= 4 ? ' selected' : ''}` }></div>
                                <div className={ `star s4${p.endChatState.rating >= 5 ? ' selected' : ''}` }></div>
                            </div>
                            <div className="sc-chat-container-end-chat-comment">
                                Simpra AI is constantly working to improve the feedback of our valued customers.
                            </div>
                        </div>
            }

        </div>
    )
}

export default EndChat;