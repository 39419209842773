const productUrls = {
    test: {
        pos: 'https://pos-qa.simprasuite.com/?skipRedirection=true',
        inventory: 'https://test-inventory.simprasuite.com/?skipRedirection=true',
        link: 'https://test-backoffice.simpralink.com/?skipRedirection=true',
        'c&p': 'https://test.checkandplace.com/?skipRedirection=true',
        loyalty: 'https://loyalty-test.simprasuite.com/?skipRedirection=true',
        pms: 'https://test.simpraproperty.net/?skipRedirection=true',
    },
    staging: {
        pos: 'https://pos-uat.simprasuite.com/?skipRedirection=true',
        inventory: 'https://staging-inventory.simprasuite.com/?skipRedirection=true',
        link: 'https://staging-backoffice.simpralink.com/?skipRedirection=true',
        'c&p': 'https://staging.checkandplace.com/?skipRedirection=true',
        loyalty: 'https://loyalty-staging.simprasuite.com/?skipRedirection=true',
        pms: 'https://staging.simpraproperty.net/?skipRedirection=true',
    },
    production: {
        pos: 'https://pos.simprasuite.com/?skipRedirection=true',
        inventory: 'https://inventory.simprasuite.com/?skipRedirection=true',
        link: 'https://backoffice.simpralink.com/?skipRedirection=true',
        'c&p': 'https://www.checkandplace.com/?skipRedirection=true',
        loyalty: 'https://loyalty.simprasuite.com/?skipRedirection=true',
        pms: 'https://pms.simprasuite.com/?skipRedirection=true',

    },
}

export default productUrls