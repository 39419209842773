import { useState, useEffect, useRef }  from 'react';
import ProductSelection                 from './product-selection'

const Message = props => {
    const p                 = props;
    const [ s, setState ]   = useState({
        isTyping: true
    });
    const iRef = useRef();

    useEffect(() => {
        let interval;
        if (iRef.current && p.type === 'response') {
            if (!p.skipAnimation) {
                const charCount = p.message.length;
                let currentChar = 0;
                interval = setInterval(() => {

                    if (iRef.current)
                        iRef.current.innerHTML = p.message.substring(0, currentChar);

                    currentChar++;
    
                    if (currentChar === charCount + 1) {
                        clearInterval(interval)
                        setState({
                            isTyping: false
                        })
                    }
    
                    p.scrollToBottom();
                }, 25);
            } else {
                iRef.current.innerHTML = p.message;
            }
        }

        p.scrollToBottom();

        return(() => {
            clearInterval(interval);
        })  
    }, []) // eslint-disable-line

    return (
        p.type === 'product'
            ?   <ProductSelection 
                    selectProduct       = { id => p.selectProduct(id) }
                    sourceProjectId     = { p.sourceProjectId }
                    isProductSelected   = { p.isProductSelected }
                />
            :   <div className={ `${p.type}${p.type === 'response' && !s.isTyping ? ' complete' : ' typing'}` }>
                    {
                        p.type === 'response'
                            ?   <div className={ `status${!s.isTyping ? ' complete' : ' typing'}` } />
                            :   null
                    }
                    <div ref={ iRef } className="post">{ p.message }</div>
                </div>
        
    )
}

export default Message;