/* eslint-disable */
export default class Validation {
    constructor(t) {
        this.t = t;
        this.name = 'Validation';
        this.ruleKeys = [ 'required', 'maxLength', 'minLength', 'minSize', 'type', 'condition', 'min', 'max', 'pattern' ]
        this.utilFuncs = {
            isValidEmail             : this.isValidEmail.bind(this),
            isValidTel               : this.isValidTel.bind(this),
            isValidUrl               : this.isValidUrl.bind(this),
            isValidDate              : this.isValidDate.bind(this),
            isValidCreditCard        : this.isValidCreditCard.bind(this),
            isValidCreditCardType    : this.isValidCreditCardType.bind(this),
            isValidPassword          : this.isValidPassword.bind(this),
        };

        this.creditCardTypes = [
            {
                id          : 'AX',
                type        : 'AmericanExpress',
                pattern     : /^3[47]/,
                maskFormat  : '#### ###### #####'
            },
            {
                id          : 'MC',
                type        : 'MasterCard',
                pattern     : /^(5[1-5]|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720)/,
                maskFormat  : '#### #### #### ####'
            },
            {
                id          : 'MA',
                type        : 'Maestro',
                pattern     : /^(5018|5020|5038|6304|6703|6708|6759|676[1-3])/,
                maskFormat  : '#### #### #### ####'
            },
            {
                id          : 'VA',
                type        : 'Visa',
                pattern     : /^4/,
                maskFormat  : '#### #### #### ####'
            },
            {
                id          : 'DC',
                type        : 'DinersClub',
                pattern     : /^(36|38|30[0-5])/,
                maskFormat  : '#### ###### ####'
            },
            {
                id          : 'DI',
                type        : 'Discover',
                pattern     : /^(6011|65|64[4-9]|622)/,
                maskFormat  : '#### #### #### ####'
            },
            {
                id          : 'JCB',
                type        : 'JCB',
                pattern     : /^35/,
                maskFormat  : '#### #### #### ####'
            }
        ]
    }

    isValidEmail(value) {
        const emailRegExp = /^((?!.*\.\.)(?!\.)[a-zA-Z0-9.!\#$%&'*+\/=?^_`{|}~-]+)@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\.[a-zA-Z0-9]{1,}$/

        if (value.match(emailRegExp)) {
            // Check if email doesn't end with a dot before the "@" sign
            const parts = value.split('@');
            if (!parts[0].endsWith('.')) {
              return true;
            }
        }

        return false;
    }

    isValidTel(value) {
        const telRegExp = new RegExp(/\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/g)
        return telRegExp.test(value);
    }

    isValidUrl(value) {
        const urlRegExp   = new RegExp(/^(http:\/\/|https:\/\/)?(www.)?((?!-)[a-zA-Z0-9-]+)\.(com|info|org|net|biz|gov|edu|xyz|ca|fr|ch|au|in|jp|mx|no|br|se|es|de|cn|uk|eu|ru|nl|us|co|tr|hu|cz|it|io|ro|bg|gr)(\.(ca|fr|ch|au|in|jp|mx|no|br|se|es|de|cn|uk|eu|ru|nl|us|co|tr|hu|cz|it|io|ro|bg|gr))?$/ig)
        return urlRegExp.test(value);
    }

    isValidPassword(value) {
        const passwordRegExp = new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);
        return passwordRegExp.test(value);
    }

    isValidDate(value) {
        /*
        Valid Values:
            YYYY-MM-DD
            YYYY/MM/DD
            YYYY.MM.DD
            YYYY MM DD
            YYYY-MM-DDTXX:XX:XXZ
            YYYY/MM/DDTXX:XX:XXZ
            YYYY.MM.DDTXX:XX:XXZ
            YYYY MM DDTXX:XX:XXZ
        */
        let dateRegExp = /^((19|20|21)\d\d[- /.](0[1-9]|1[012]|[1-9])[- /.](0[1-9]|[12][0-9]|3[01]|[1-9])(T[012][0-3]:[0-5][0-9]:[0-5][0-9]Z))|(19|20|21)\d\d[- /.](0[1-9]|1[012]|[1-9])[- /.](0[1-9]|[12][0-9]|3[01]|[1-9])$/g;
        return dateRegExp.test(value);
    }

    isValidCreditCardType(value, typeId) {
        // Kredi kartı type kontrolünden önce kart numarası valide edilir.
        const isValidCreditCard = this.isValidCreditCard(value);
        if (isValidCreditCard && !!typeId) {
            // Kredi kartı numarası belirtilen typeId (Backend tarafından sunulan id) ile eşleştirilir.
            let creditCardType    = this.creditCardTypes.find(ccType => ccType.id === typeId);
            if ( !creditCardType ) return true;
            let cardPattern       = new RegExp(creditCardType.pattern)
            return cardPattern.test(value); // Kredi kartı tipi regex patterni ile valide edilir.
        }

        //Eğer valid bir kredi kartı numarasıysa type kontrolü yapılmadan false döner.
        return false;
    }

    isValidCreditCard(value) {
        if (/[^0-9-\s]+/.test(value)) return false;
        let nCheck = 0;
        let bEven = false;
        let newValue = value.replace(/\D/g, "");
    
        for (var n = newValue.length - 1; n >= 0; n--) {
            var cDigit = newValue.charAt(n),
                nDigit = parseInt(cDigit, 10);
    
            if (bEven && (nDigit *= 2) > 9) 
                nDigit -= 9;
    
            nCheck += nDigit;
            bEven = !bEven;
        }
    
        return nCheck % 10 === 0;
    }

    isValidCreditCardExpireDate(value) {
        let d           = new Date();
        let thisYear    = d.toLocaleDateString(undefined, { year  : '2-digit' });
        let thisMonth   = d.toLocaleDateString(undefined, { month : '2-digit' });
        
        let month = '', year = '';
        if (value.includes('/')) {
            [ month, year ] = value.split('/');
        } else {
            return false;
        }

        if (year === '')
            return false;

        if (parseInt(year) === parseInt(thisYear) && parseInt(month) < parseInt(thisMonth))
            return false;
            
        if (parseInt(year) >= parseInt(thisYear))
            return true;

        return false;
    }

    checkRequiredError(rule, key, value) {
        if (value instanceof Array && rule[key] === 'array') {
            const errors = value.map(val => { 
                const v     = val === null || val === undefined ? '' : val;
                const error = this.checkRequiredError(rule, key, v);
                if (error) return [ error ];
                return null;
            });

            const isAllValid = errors.every(error => error === null);
            return errors.length > 0 && !isAllValid ? errors : null;
        }

        
        if (value.length === 0 && value !== 0) {
            return ({ type: key, message: this.t('UiKit_Fill_Out_Field_Label') });
        }
    }

    checkFieldError(rule, key, data, value) {
        if (key === 'required' && rule[key]) 
            return this.checkRequiredError(rule, key, value);

        if (key === 'maxLength' && value.length > rule[key])
            return ({ type: key, message: this.t('UiKit_Fill_Max_Length_Field_Label').replace('{{maxLength}}', rule[key]) });

        if (key === 'minLength' && value.length < rule[key])
            return ({ type: key, message: this.t('UiKit_Fill_Min_Length_Field_Label').replace('{{minLength}}', rule[key] - 1) });

        if (key === 'minSize' && value.length < rule[key])
            return ({ type: key, message: this.t('UiKit_Fill_Min_Size_Field_Label') });

        if (key === 'type' && value.length > 0) {
            if (rule[key] === 'email' &&  !this.isValidEmail(value))
                return ({ type: key, message: this.t('UiKit_Fill_Valid_Email_Label') });

            if (rule[key] === 'tel' &&  !this.isValidTel(value))
                return ({ type: key, message: this.t('UiKit_Fill_Valid_Phone_Number_Label') });

            if (rule[key] === 'url' &&  !this.isValidUrl(value))
                return ({ type: key, message: this.t('UiKit_Fill_Valid_Url_Label')  });

            if (rule[key] === 'creditCardNumber' &&  !this.isValidCreditCard(value))
                return ({ type: key, message: this.t('UiKit_CreditCard_CardNumber_Valid_Credit_Card_Label') });

            if (rule[key] === 'creditCardExpireDate' &&  !this.isValidCreditCardExpireDate(value))
                return ({ type: key, message: this.t('UiKit_CreditCard_CardNumber_Valid_Expire_Date_Label') });
            
            if (rule[key] === 'date' &&  !this.isValidDate(value))
                return ({ type: key, message: this.t('UiKit_Fill_Valid_Date_Label') });
        }

        if (key === 'condition') {
            if (rule[key] instanceof Array) {
                let condErrors = [];
                rule[key].forEach(cond => {
                    if (cond['func'](data, value, this.utilFuncs))
                        return condErrors.push({ type: key, message: cond['message'] || this.t('UiKit_Check_This_Field_Label') });
                });
                return condErrors.length > 0 ? condErrors : null;
            } else {
                if (rule[key]['func'](data, value, this.utilFuncs))
                    return ({ type: key, message: rule[key]['message'] || this.t('UiKit_Check_This_Field_Label') });
            }
        }

        if (key === 'min' &&  value < rule[key]) 
            return ({ type: key, message: this.t('UiKit_Min_Value_Field_Label').replace('{{minValue}}', value) });

        if (key === 'max' &&  value > rule[key]) 
            return ({ type: key, message: this.t('UiKit_Max_Value_Field_Label').replace('{{minValue}}', value) });

        if (key === 'pattern' &&  new RegExp(rule[key]).test(value))
            return ({ type: key, message: this.t('UiKit_Fill_Valid_Format_Label') });
    }

    validateField(field, rule, data, value, valid) {
        let errors = [];
        let hasValidationKey = this.ruleKeys.some(key => Object.keys(rule).includes(key));
        if (value instanceof Array && !hasValidationKey) {
            let childErrors = value.map(childValue => {
                let errorObject = {}
                Object
                .keys(rule)
                .forEach(key => {
                    let { isValid, errors } = this.validateField(key, rule[key], childValue, childValue[key], valid)
                    valid = isValid;
                    errorObject[key] = errors;
                })
                return errorObject;
            })
            if (childErrors) errors.push(...childErrors);
        } else if (value instanceof Object && !hasValidationKey) {
            let errors = Object.keys(rule).reduce((o, key) => {
                let { isValid, errors } = this.validateField(key, rule[key], value, value[key], valid)
                valid = isValid;
                o[key] = errors;
                return o;
            }, {})
            return {
                isValid: valid,
                errors,
            }
        } else {
            Object
            .keys(rule)
            .forEach(key => {
                let val = value === null || value === undefined ? '' : value;
                let error = this.checkFieldError(rule, key, data, val);
                if (error && error instanceof Array) {
                    valid = false;
                    errors = errors.concat(error);
                } else if (error) {
                    valid = false;
                    errors.push(error);
                }
            })
        }

        return {
            isValid: valid,
            errors: errors.length > 0 ? errors : null
        };
    }
    
    validateData(rules, data) {
        let fields = {};
        let valid = true

        Object
        .keys(rules)
        .forEach(key => {
            let { isValid, errors } = this.validateField(key, rules[key], data, data[key], valid)
            fields[key] = errors;
            valid = isValid;
        });

        return {
            valid,
            fields
        }            
    }
}
/* eslint-enable */