import Product                  from './product'
import AuthorizationContext     from 'auth/keycloak-instance';
import useTranslate             from 'app/common/hooks/translate';

const ProductList = props => {
    const authorizedProducts    = AuthorizationContext.user.authorizedProducts;
    const otherProducts         = AuthorizationContext.user.unAuthorizedProducts
    const t                     = useTranslate();

    const renderProductList = () => {
        return (
            authorizedProducts
            .map((item, index) => (
                <Product 
                    item                = { item } 
                    index               = { index } 
                    key                 = { 'p-' + index }
                    authorizedProducts  = { authorizedProducts } 
                    toggleApplicationLoader = { (url) => props.toggleApplicationLoader(url) }
                />
            ))
        )
    }
    const renderOtherProductList = () => {
        return (
            otherProducts
            .map((item, index) => (
                <Product 
                    item                    = { item }
                    index                   = { index }
                    key                     = { 'p-' + index }
                    authorizedProducts      = { authorizedProducts }
                    toggleApplicationLoader = { (url) => props.toggleApplicationLoader(url) }
                />
            ))
        )
    }

    return (
        <div className="product-selection-container">
            <h1>{ t('Your_Products') }</h1>
            <h2>{ t('Your_Product_Message') }</h2>
            <div className="product-list-container" style={{ gridTemplateRows: `repeat(${Math.round(authorizedProducts.length / 3)}, 1fr)` }}>
                { renderProductList() }
            </div>

            <div className="other-products">
                <h1>{ t('All_Other_Products') }</h1>
                <h2>{ t('Your_Product_Message') }</h2>
                <div className="product-list-container others" style={{ gridTemplateRows: `repeat(${Math.round(otherProducts.length / 3)}, 1fr)` }}>
                    { renderOtherProductList() }
                </div>
            </div>
        </div>
    )
}

export default ProductList