const DateCore = (() => {
    let _timeZone;

    const init = tz => {
        _timeZone = tz;
    }

    const timeZone = () => { return _timeZone }

    return {
        init,
        timeZone
    }
})()
  
export default DateCore;